import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select, Button, Container, Grid } from '@mui/material';
import Layout from '../../Components/Layout/Layout';
import axios from '../../Components/axios';
import './_shipping.scss';

const Shipping = () => {
  const location = useLocation();
  const { state } = location;

  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedOption, setSelectedOption] = useState('Retrait Imprimerie');

  useEffect(() => {
    if (!(state === "modify" || (state && state.cartItemIds))) {
      window.location.href = '/';
    }

    const fetchAddresses = async () => {
      try {
        const response = await axios.get('/getAllAddresses');
        const data = await response.data;
        setAddresses(data);

        if (data.length > 0) {
          setSelectedAddress(data[0].adresse_id);
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses();
  }, [state]);

  const handleContinueClick = () => {
    const selectedAddressObject = addresses.find((address) => address.adresse_id === selectedAddress);

    if (selectedAddressObject) {
      const checkoutURL = `/checkout?address=${encodeURIComponent(JSON.stringify(selectedAddressObject))}&option=${encodeURIComponent(selectedOption)}&cartItemIds=${state.cartItemIds}`;

      window.location.href = checkoutURL;
    }
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Layout>
      <div className='div-shipping'>
        <Container className="container-shipping" maxWidth='lg'>
        <h2 className='title-shipping'>Livraison</h2>
          <Grid container direction="column" alignItems="center">
            <FormControl fullWidth variant="outlined" id='form-control-shipping'>
              <InputLabel htmlFor="outlined-address">Choisissez une adresse</InputLabel>
              <Select
                name="address"
                value={selectedAddress}
                onChange={handleAddressChange}
                label="Choisissez une adresse"
                color='primary'
              >
                {addresses.map((address) => (
                  <MenuItem key={address.adresse_id} value={address.adresse_id}>
                    {address.entreprise} - {address.adresse}, {address.ville} {address.abreviation} {address.code_postal}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth variant="outlined" id='form-control-shipping'>
              <InputLabel htmlFor="outlined-option">Choisissez une option</InputLabel>
              <Select
                name="option"
                value={selectedOption}
                onChange={handleOptionChange}
                label="Choisissez une option"
                color='primary'
              >
                <MenuItem value="Retrait Imprimerie">Retrait Imprimerie</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="info"
              onClick={handleContinueClick}
              id="button-shipping"
            >
              Continuer
            </Button>
          </Grid>
        </Container>
      </div>
    </Layout>
  );
};

export default Shipping;