import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState, useContext } from 'react';
import axios from '../../Components/axios';
import { AuthContext } from '../../Components/context/AuthContext';
import { Container, Button, Grid, MenuItem, TextField, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Layout from '../../Components/Layout/Layout';
import NoRowsOverlay from '../../Components/NoRowsOverlay';
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './_order.scss';


const Orders = () => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const { currentUserId, currentUserRole } = useContext(AuthContext);
    const [originalOrders, setOriginalOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("Toutes les adresses");
    const [selectedUser, setSelectedUser] = useState("Tous les utilisateurs");
    const [searchTerm, setSearchTerm] = useState('');

    dayjs.locale('fr');
    dayjs.extend(relativeTime);

    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(date).toLocaleDateString('fr-FR', options);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = currentUserId;
                const user_role = currentUserRole;

                let response;

                if (user_role === "administrateur") {
                    response = await axios.get(`/getAllOrders`);
                }
                else {
                    response = await axios.get(`/getOrder/user/${userId}`);
                }

                const ordersWithId = response.data.orders.map((order, index) => ({
                    ...order,
                    id: index + 1,
                    user_address_full: `${order.entreprise} | ${order.ville} | ${order.departement_region} | ${order.code_postal} (${order.code_localisation})`
                }));
                setOriginalOrders(ordersWithId);
                setFilteredOrders(ordersWithId);

                const uniqueAddresses = Array.from(
                    new Set(ordersWithId.map(order => order.user_address_full))
                );

                const uniqueUsers = Array.from(
                    new Set(ordersWithId.map(order => order.user_fullname))
                );

                setAddresses(uniqueAddresses);
                setUsers(uniqueUsers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentUserId, currentUserRole]);


    const columns = [
        {
            field: 'commande_id',
            headerName: 'ID de commande',
            width: 164,
            renderCell: (params) => (
                <Tooltip title="Voir" arrow>
                    <Button
                        color="primary"
                        onClick={() => handleOrderClick(params.row.commande_id)}
                    >
                        {params.row.commande_id}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'date_commande',
            headerName: 'Date de la commande',
            width: 164,
            valueGetter: (params) => params.row.date_commande,
            valueFormatter: (params) => formatDate(params.value),
        },
        { field: 'user_fullname', headerName: 'Commandé par', width: 164 },
        { field: 'reference', headerName: 'Bon de commande', width: 164 },
        { field: 'product_name', headerName: 'Produit', width: 164 },
        {
            field: 'product_image',
            headerName: 'Image',
            width: 160,
            renderCell: (params) => (
                <img
                    src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${params.value}`}
                    alt={params.row.product_name}
                    style={{ width: '140px' }}
                />
            ),
        },
        { field: 'quantite', headerName: "Total d'articles", type: 'number', width: 164 },
        { field: 'statut', headerName: 'Statut', width: 108 },
    ];

    const handleAddressChange = (event) => {
        setSelectedAddress(event.target.value);
    };

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleOrderClick = (orderId) => {
        const selectedOrder = originalOrders.find(order => order.commande_id === orderId);

        if (selectedOrder) {
            const {
                commande_id,
                reference,
                date_commande,
                statut,
                quantite,
                mode_impression,
                image_recto,
                image_verso,
                commentaire,
                product_name,
                product_image,
                product_largeur,
                product_hauteur,
                address,
                entreprise,
                ville,
                departement_region,
                code_postal,
                user_fullname,
                user_mail,
                option_livraison
            } = selectedOrder;

            const orderURL = `/order-details?commande_id=${commande_id}&reference=${encodeURIComponent(reference)}&date_commande=${date_commande}&statut=${statut}&quantite=${quantite}&mode_impression=${mode_impression}&image_recto=${encodeURIComponent(image_recto)}&image_verso=${encodeURIComponent(image_verso)}&commentaire=${encodeURIComponent(commentaire)}&product_name=${encodeURIComponent(product_name)}&product_image=${encodeURIComponent(product_image)}&product_largeur=${product_largeur}&product_hauteur=${product_hauteur}&address=${encodeURIComponent(address)}&entreprise=${encodeURIComponent(entreprise)}&ville=${encodeURIComponent(ville)}&departement_region=${encodeURIComponent(departement_region)}&code_postal=${code_postal}&user_fullname=${encodeURIComponent(user_fullname)}&user_mail=${encodeURIComponent(user_mail)}&option_livraison=${encodeURIComponent(option_livraison)}`;

            window.open(orderURL, '_blank');
        } else {
            console.error('Order not found');
        }
    };

    const handleFilter = () => {
        let newlyFilteredOrders = originalOrders;

        if (startDate) {
            newlyFilteredOrders = newlyFilteredOrders.filter(order => {
                const orderDate = new Date(order.date_commande);
                const startDateTime = new Date(startDate);
                startDateTime.setHours(0, 0, 0, 0);
                orderDate.setHours(0, 0, 0, 0);
                return orderDate >= startDateTime;
            });
        }
        if (endDate) {
            newlyFilteredOrders = newlyFilteredOrders.filter(order => {
                const orderDate = new Date(order.date_commande);
                const endDateTime = new Date(endDate);
                endDateTime.setHours(0, 0, 0, 0);
                orderDate.setHours(0, 0, 0, 0);
                return orderDate <= endDateTime;
            });
        }

        if (selectedAddress !== "Toutes les adresses") {
            newlyFilteredOrders = newlyFilteredOrders.filter(order => order.address_full === selectedAddress);
        }

        if (selectedUser !== "Tous les utilisateurs") {
            newlyFilteredOrders = newlyFilteredOrders.filter(order => order.user_fullname === selectedUser);
        }

        if (searchTerm) {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            newlyFilteredOrders = newlyFilteredOrders.filter((order) =>
                order.commande_id.toString().includes(lowerCaseSearchTerm) ||
                order.reference.toLowerCase().includes(lowerCaseSearchTerm) ||
                order.quantite.toString().includes(lowerCaseSearchTerm) ||
                order.product_name.toLowerCase().includes(lowerCaseSearchTerm) ||
                order.user_fullname.toLowerCase().includes(lowerCaseSearchTerm)
            );
        }

        setFilteredOrders(newlyFilteredOrders);
    };

    const getFirstDayOfMonth = () => {
        const today = dayjs();
        return today.startOf('month');
    };

    const getLastDayOfMonth = () => {
        const today = dayjs();
        return today.endOf('month');
    };

    const getFirstDayOfYear = () => {
        const today = dayjs();
        return today.startOf('year');
    };

    const getLastDayOfYear = () => {
        const today = dayjs();
        return today.endOf('year');
    };

    const getEmptyDate = () => null;

    const exportToExcel = async () => {
        const ordersData = filteredOrders.map(order => ({
            'OrderNumber': order.commande_id,
            'PONumber': order.reference,
            'OrderDate': formatDate(order.date_commande),
            'ProductName': order.product_name,
            'Quantity': order.quantite,
            'Price': parseFloat(order.prix), // Convertir en nombre
        }));

        let totalPrice = 0;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Commandes');

        const columns = Object.keys(ordersData[0]);

        const titleRow = worksheet.addRow(columns);

        titleRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD3D3D3' },
            };
        });

        ordersData.forEach(order => {
            const row = columns.map(column => {
                if (column === 'Price') {
                    totalPrice += order[column];
                }
                return order[column];
            });
            worksheet.addRow(row);
        });

        const totalRow = worksheet.addRow(['', '', '', '', '', 'Prix Total:', totalPrice]);

        totalRow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        columns.forEach((column, index) => {
            const col = worksheet.getColumn(index + 1);
            col.width = column.length + 5;
        });

        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'commandes.xlsx';
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };

    const exportToPDF = () => {
        const pdf = new jsPDF();

        pdf.text("Historique des commandes", 70, 10)

        const columns = [
            { title: 'OrderNumber', dataKey: 'commande_id' },
            { title: 'PONumber', dataKey: 'reference' },
            { title: 'OrderDate', dataKey: 'date_commande' },
            { title: 'ProductName', dataKey: 'product_name' },
            { title: 'Quantity', dataKey: 'quantite' },
            { title: 'Price', dataKey: 'prix' },
        ];

        const ordersData = filteredOrders.map(order => ({
            commande_id: order.commande_id,
            reference: order.reference,
            date_commande: formatDate(order.date_commande),
            product_name: order.product_name,
            quantite: order.quantite,
            prix: parseFloat(order.prix),
        }));

        pdf.autoTable({ columns, body: ordersData });

        const totalPrice = ordersData.reduce((total, order) => total + order.prix, 0);

        pdf.text(`Prix Total: ${totalPrice.toFixed(2)}`, 150, pdf.internal.pageSize.height - 10);

        pdf.save('commandes.pdf');
    };

    const shortcutsItems = [
        {
            label: 'Premier jour du mois',
            getValue: getFirstDayOfMonth,
        },
        {
            label: 'Dernier jour du mois',
            getValue: getLastDayOfMonth,
        },
        {
            label: 'Premier jour de l\'année',
            getValue: getFirstDayOfYear,
        },
        {
            label: 'Dernier jour de l\'année',
            getValue: getLastDayOfYear,
        },
        {
            label: 'Réinitialiser',
            getValue: getEmptyDate,
        },
    ];

    return (
        <Layout>
            <Container maxWidth="xl" id='container-order'>
                <h1 className='title-order'>Historique des commandes</h1>
                <Grid container spacing={2} alignItems="center" id='grid-order'>
                    {currentUserRole === "administrateur" && (
                        <>
                            <Grid item xs={12} textAlign='center'>
                                <Button
                                    variant="contained"
                                    color="success"
                                    style={{ width: 'fit-content' }}
                                    id="button-export"
                                    onClick={exportToExcel}
                                    fullWidth
                                >
                                    Exporter en Excel
                                </Button>
                            </Grid>
                            <Grid item xs={12} textAlign='center'>
                                <Button
                                    variant="contained"
                                    color="info"
                                    style={{ width: 'fit-content' }}
                                    id="button-export-pdf"
                                    onClick={exportToPDF}
                                    fullWidth
                                >
                                    Exporter en PDF
                                </Button>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            label="Rechercher"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleFilter();
                                }
                            }}
                            color='primary'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
                            <DatePicker
                                orientation='portrait'
                                value={startDate}
                                onChange={(newDate) => setStartDate(newDate)}
                                label="Date de début"
                                format="DD/MM/YYYY"
                                fullWidth
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
                            <DatePicker
                                orientation='portrait'
                                value={endDate}
                                onChange={(newDate) => setEndDate(newDate)}
                                label="Date de fin"
                                format="DD/MM/YYYY"
                                fullWidth
                                slotProps={{
                                    shortcuts: {
                                        items: shortcutsItems,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            select
                            label="Facturation à"
                            value={selectedAddress}
                            onChange={handleAddressChange}
                            id='address-field'
                            color='primary'
                            fullWidth
                        >
                            <MenuItem value="Toutes les adresses">Toutes les adresses</MenuItem>
                            {addresses.map((address_full, index) => (
                                <MenuItem key={index} value={address_full}>
                                    {address_full}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {currentUserRole === "administrateur" && (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label="Commandé Par"
                                    value={selectedUser}
                                    onChange={handleUserChange}
                                    id='user-field'
                                    color='primary'
                                    fullWidth
                                >
                                    <MenuItem value="Tous les utilisateurs">Tous les utilisateurs</MenuItem>
                                    {users.map((user_fullname, index) => (
                                        <MenuItem key={index} value={user_fullname}>
                                            {user_fullname}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" id='button-order' onClick={handleFilter} fullWidth>
                            Filtrer
                        </Button>
                    </Grid>
                </Grid>
                <DataGrid
                    rows={filteredOrders}
                    rowHeight={120}
                    autoHeight
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                    sx={{ '--DataGrid-overlayHeight': '300px' }}
                    className="containedDataGrid"
                />
            </Container>
        </Layout>
    );
};

export default Orders;