import React, { useState, useEffect, useContext } from 'react';
import axios from '../axios';
import { Link } from 'react-router-dom';
import {
    Card,
    CardContent,
    Button,
    Typography,
    IconButton,
    InputBase,
    Badge,
    Menu,
    MenuItem,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ShoppingCartContext } from '../context/ShoppingCartContext';

export default function Layout(props) {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const navigate = useNavigate();
    const { currentUser, logout } = useContext(AuthContext);
    const { numberOfProducts } = useContext(ShoppingCartContext);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);

    const pages = ['Produits', 'Historique', 'Sauvegardé', `${currentUser}`];
    const pageLinks = ['/', '/order', '/savedshoppingcart', null];

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleLogout = async () => {
        try {
            const res = await logout();
            if (res.data.Status === 'deconnected') {
                navigate('/login');
            } else {
                alert(res.data.Error);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('/getAllProducts');
                setProducts(response.data.products);
                setFilteredProducts(response.data.products);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    const res = await logout();
                    if (res.data.Status === 'deconnected') {
                        navigate('/login');
                    } else {
                        alert(res.data.Error);
                    }
                } else {
                    console.error('Erreur lors de la récupération des produits :', error);
                }
            }
        };

        fetchProducts();
    }, [logout, navigate]);

    const handleSearch = () => {
        if (searchTerm.trim() === '') {
            return;
        }

        const filtered = products.filter(
            (product) =>
                product.nom &&
                product.nom.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredProducts(filtered);
        setSearchClicked(true);
        handleCloseNavMenu();

        if (filtered.length === 0) {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleMoreMenuOpen = (event) => {
        setMoreMenuAnchorEl(event.currentTarget);
    };

    const handleMoreMenuClose = () => {
        setMoreMenuAnchorEl(null);
    };

    return (
        <>
            <AppBar position="sticky" color="navbar">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            <img alt="Logo" src='/images/gobiLAB_logo.png' width="120" height="65" className="logo-nav" />
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu} component="a" href={pageLinks[index]}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                                <MenuItem>
                                    <InputBase
                                        type="text"
                                        placeholder="Rechercher"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <IconButton onClick={handleSearch} type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                            }}
                        >
                            <img alt="Logo" src='/images/gobiLAB_logo.png' width="100" height="54" className="logo-nav" />
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page, index) => (
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: pageLinks[index] ? 'black' : 'white', display: 'block' }}
                                    href={pageLinks[index]}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' } }}>
                            <Paper>
                                <div className="m-1">
                                    <InputBase
                                        type="text"
                                        placeholder="Rechercher dans le site"
                                        value={searchTerm}
                                        sx={{ width: '168px' }}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <IconButton onClick={handleSearch} type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </div>
                            </Paper>
                        </Box>

                        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                            <Tooltip title="Panier">
                                <IconButton color="inherit" href="/shoppingcart">
                                    <Badge badgeContent={numberOfProducts} color="primary">
                                        <ShoppingCartIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Mon compte">
                                <IconButton color="inherit" href="/user">
                                    <AccountCircleIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Déconnexion">
                                <IconButton color="inherit" onClick={handleLogout}>
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                color="inherit"
                                onClick={handleMoreMenuOpen}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={moreMenuAnchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                id="more-menu-appbar"
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(moreMenuAnchorEl)}
                                onClose={handleMoreMenuClose}
                            >
                                <MenuItem>
                                    <IconButton color="inherit" href="/shoppingcart">
                                        <Badge badgeContent={numberOfProducts} color="primary">
                                            <ShoppingCartIcon />
                                        </Badge>
                                    </IconButton>
                                </MenuItem>
                                <MenuItem>
                                    <IconButton color="inherit" href="/user">
                                        <AccountCircleIcon />
                                    </IconButton>
                                </MenuItem>
                                <MenuItem>
                                    <IconButton color="inherit" onClick={handleLogout}>
                                        <ExitToAppIcon />
                                    </IconButton>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <main>
                {searchClicked ? (
                    <div className="product-cards-home">
                        {noResults ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <h3 className='mt-2'>
                                        Aucune information correspondant à votre recherche n'a été trouvée.
                                    </h3>
                                    <Button variant="contained" href='/' style={{ width: 'fit-content', margin: 'auto' }}>
                                        Retourner à l'accueil
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            filteredProducts.map((product) => (
                                <Card className="product-card-home" key={product.produit_id}>
                                    {product.image && (
                                        <Link to="/product" state={{ product: product }}>
                                            <img
                                                className="img-home"
                                                src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${product.image}`}
                                                alt={product.nom}
                                            />
                                        </Link>
                                    )}
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            {product.nom}
                                        </Typography>
                                        <Link to="/product" state={{ product: product }}>
                                            <Button variant="contained" color='info' className="button-home">
                                                Accéder au produit
                                            </Button>
                                        </Link>
                                    </CardContent>
                                </Card>
                            ))
                        )}
                    </div>
                ) : (
                    props.children
                )}
            </main>
        </>
    );
}