import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './_legalNotice.scss';

const LegalNotice = () => {
    const navigate = useNavigate();
    return (
        <Container maxWidth="md" className="mentions-legales">
            <Box textAlign="center">
                <Button variant='outlined' onClick={() => navigate('/login')} color='primary'>
                    <ArrowBackIcon />
                </Button>
            </Box>
            <Typography variant="h1" gutterBottom>
                <strong>Mentions Légales</strong>
            </Typography>
            <Typography paragraph>
                Ceci constitue les mentions légales de notre site web.
            </Typography>

            {/* Éditeur et Directeur de la publication */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Éditeur et Directeur de la publication</strong>
                </Typography>
                <Typography paragraph>
                    Le propriétaire et éditeur du site www.imprimerie-rgi.com est la société RGI RÉALISATION GRAPHIQUE IMPRESSION (ci-après « IMPRIMERIE RGI » ou « RGI » ou « l’Éditeur »), Société par Actions Simplifiée, au capital social de 250 000 euros, immatriculée au Registre du Commerce et des Sociétés de Meaux, sous le numéro 329 167 001 et dont le siège social se situe au 6 rue Paul Henri Spaak – 77400 Saint-Thibault-des-Vignes. Le numéro de TVA intracommunautaire est FR81329167001. Le Directeur de la publication est Philippe de Moura, lequel peut être contacté aux coordonnées suivantes : 01 60 07 26 56 ou à contact@imprimerie-rgi.fr.
                </Typography>
            </div>

            {/* Hébergement */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Hébergement</strong>
                </Typography>
                <Typography paragraph>
                </Typography>
            </div>

            {/* Accessibilité du site */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Accessibilité du site</strong>
                </Typography>
                <Typography paragraph>
                    IMPRIMERIE RGI met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de qualité et une disponibilité constante du présent site. Néanmoins, IMPRIMERIE RGI ne pourra être tenue pour responsable en cas d’indisponibilité du site ou d’une mauvaise qualité. En outre, IMPRIMERIE RGI ne peut garantir l’exactitude des informations diffusées via son site dont il n’en serait pas l’auteur. IMPRIMERIE RGI ne pourra être tenue pour responsable des dommages de toute nature, directs ou indirects résultant de l’utilisation du présent site.
                </Typography>
            </div>

            {/* Contenu du site – Propriété intellectuelle */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Contenu du site – Propriété intellectuelle</strong>
                </Typography>
                <Typography paragraph>
                    Tous les éléments qui composent le site, et notamment les marques, logos, textes, images, sons, fichiers, données, dessins, graphiques, photos, animations, agencements, charte graphique, sont et restent la propriété de l’Éditeur ou celle de ses ayants droit. La reproduction de tout ou partie de ce site ou les éléments qui le composent sur un support, quel qu'il soit est, formellement interdite, sauf autorisation expresse et préalable de la part de l’Éditeur. La reproduction n’est autorisée uniquement à des fins exclusives d’information pour un usage personnel et privé. De manière générale, toute reproduction doit impérativement mentionner le nom de son auteur. L’ensemble des contenus du site (images, textes, base de données…) est soumis à la législation française et internationale en vigueur en matière de propriété intellectuelle (droit d’auteur, droit des marques, droit des bases de données, etc.). Si vous constatez que des éléments publiés sur le Site portent atteinte à des droits d’auteur ou autres droits protégés, merci de contacter l’Éditeur à l’adresse mail contact@imprimerie-rgi.fr ou par courrier au 6 rue Paul Henri Spaak – 77400 Saint-Thibault-des-Vignes. Par ailleurs, il est rappelé que conformément à l’article L.342-1 du Code de la propriété intellectuelle, est strictement interdit le téléchargement de la totalité ou d’une partie quantitativement substantielle de cette base par utilisation de robots ou de tout autre procédé d’extraction automatique.
                </Typography>
            </div>

            {/* Données personnelles */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Données personnelles</strong>
                </Typography>
                <Typography paragraph>
                    La société RGI RÉALISATION GRAPHIQUE IMPRESSION est le responsable de traitement des données collectées sur le site. Vous êtes informé que les données collectées via le site sont nécessaires pour répondre à vos commandes.
                </Typography>
            </div>

            {/* Liens externes */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Liens externes</strong>
                </Typography>
                <Typography paragraph>
                    Le site peut comporter également des informations mises à disposition par des sites externes ou des liens vers d'autres sites qui n'ont pas été développés par l’Éditeur. Il appartient à l’internaute d'utiliser ces informations avec discernement, étant entendu que l’Éditeur ne pourra pas être tenu pour responsables de la mise à disposition de ces sites et également de leur contenu ou toute autre information disponible sur ou à partir de ces sites. En outre, l’Éditeur ne pourra être tenu pour responsable de tous dommages ou pertes avérés, allégués, consécutifs ou en relation avec l’utilisation ou le fait d’avoir confiance au contenu de ces sites.
                </Typography>
            </div>

            {/* Modification */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Modification</strong>
                </Typography>
                <Typography paragraph>
                    L’Éditeur se réserve le droit de modifier ou de mettre à jour les présentes mentions légales, notamment en cas de l’évolution de la législation ou du site, à tout moment et sans préavis. Ainsi, l’internaute est invité à consulter régulièrement les présentes mentions légales.
                </Typography>
            </div>

            {/* Compétence et droit applicable */}
            <div className="section">
                <Typography variant="h2" gutterBottom>
                    <strong>Compétence et droit applicable</strong>
                </Typography>
                <Typography paragraph>
                    Les présentes mentions légales sont soumises pour leur validité, leur interprétation et leur exécution au droit français. En cas de litige, l’internaute est invité, dans un premier temps, à prendre attache avec l’Éditeur et dans un second temps, à défaut de solution apportée, il pourra saisir le tribunal compétent dans le ressort du siège social de l’Éditeur.
                </Typography>
            </div>
        </Container>
    );
};

export default LegalNotice;