import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from '../../Components/axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import Layout from '../../Components/Layout/Layout';

// Contexts
import { AuthContext } from '../../Components/context/AuthContext';
import { ShoppingCartContext } from '../../Components/context/ShoppingCartContext';

// MUI Components
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Modal,
    Box,
    Alert,
    AlertTitle,
    CircularProgress
} from '@mui/material';

// MUI Icons
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import './_shoppingcart.scss';

const ShoppingCart = () => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const [cartItems, setCartItems] = useState([]);
    const modify = "shoppingCart";
    const [savedItems, setSavedItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { currentUserId } = useContext(AuthContext);
    const { updateNumberOfProducts } = useContext(ShoppingCartContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileExist, setIsFileExist] = useState(false);
    const navigate = useNavigate();

    const toggleEnregistred = async (itemId, isSavedItem) => {
        try {
            if ((isSavedItem && cartItems.length === 0) || !isSavedItem) {
                setIsLoading(true);
                await axios.get(`/toggle/${itemId}`);
                setIsLoading(false);
                fetchShoppingCart();
                updateNumberOfProducts();
            } else {
                setIsLoading(false);
                setShowModal(true);
                return;
            }
        } catch (error) {
            console.error('Erreur lors du basculement de l\'enregistrement', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const deleteCartItem = async (itemId, image_recto, image_verso) => {
        try {
            const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer ce produit du panier ?');

            if (confirmDelete) {
                setIsLoading(true);
                await axios.delete(`/deleteShoppingCart/${itemId}`);
                if (image_recto) {
                    const rectoWithoutExtension = image_recto.replace(/\.[^/.]+$/, "");
                    await deleteCloudinaryFile(rectoWithoutExtension);
                }
                if (image_verso) {
                    const versoWithoutExtension = image_verso.replace(/\.[^/.]+$/, "");
                    await deleteCloudinaryFile(versoWithoutExtension);
                }
                setIsLoading(false);
                fetchShoppingCart();
                updateNumberOfProducts();
            }
        } catch (error) {
            console.error('Erreur lors de la suppression du produit', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const deleteCloudinaryFile = async (publicId) => {
        try {
            const response = await axios.delete(`/product/file/${publicId}`);

            if (response.status === 200) {
            } else {
                console.error('Error deleting file from backend:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting file from backend:', error.message);
        }
    };

    const modifyCartItem = (product) => {
        navigate('/product', { state: { product, modify } });
    };

    const fetchShoppingCart = useCallback(async () => {
        try {
            const userId = currentUserId;
            const response = await axios.get(`/getAllShoppingCart/user/${userId}`);
            const allItems = response.data.products;

            setCartItems(allItems.filter(item => item.est_enregistre === 0));
            setSavedItems(allItems.filter(item => item.est_enregistre === 1));
        } catch (error) {
            console.error('Erreur lors de la récupération du panier', error);
        }
    }, [currentUserId]);

    useEffect(() => {
        fetchShoppingCart();
    }, [fetchShoppingCart]);

    useEffect(() => {
        const fetchData = async () => {
            const checkFileExistence = async (filePublicId) => {
                try {
                    const response = await axios.get(`/product/pdf-exists/${filePublicId}`);
                    const fileExists = response.data.exists;
                    return fileExists;
                } catch (error) {
                    console.error('Erreur lors de la vérification du fichier sur le backend', error);
                    return false;
                }
            };

            if (cartItems.length !== 0) {
                if (cartItems[0].image_recto) {
                    const isFrontExist = await checkFileExistence(cartItems[0].image_recto.replace(/\.[^/.]+$/, ""));
                    if (!isFrontExist) {
                        setIsFileExist(true);
                        toast.error(`l'image recto du produit sélectionné n'a pas été téléchargée sur nos serveurs. Vous devrez recommencer la commande en fournissant le fichier PDF pour le recto du produit.`, {
                            autoClose: 5000,
                        });
                    }
                }
                if (cartItems[0].mode_impression === "Quadri recto/verso" && cartItems[0].image_verso) {
                    const isBackExist = await checkFileExistence(cartItems[0].image_verso.replace(/\.[^/.]+$/, ""));
                    if (!isBackExist) {
                        setIsFileExist(true);
                        toast.error(`l'image verso du produit sélectionné n'a pas été téléchargée sur nos serveurs. Vous devrez recommencer la commande en fournissant le fichier PDF pour le verso du produit.`, {
                            autoClose: 5000,
                        });
                    }
                }
            }
        };
        fetchData();
    }, [cartItems]);

    return (
        <Layout>
            <ToastContainer />
            <Container className="container" maxWidth="xl">
                <h1 className='title-shoppingcart'>Votre Panier</h1>
                {cartItems.length === 0 && savedItems.length === 0 ? (
                    <div className="panier-vide-page">
                        <ProductionQuantityLimitsIcon style={{ fontSize: 200 }} />
                        <Typography variant="h5">Votre panier est vide</Typography>
                        <Typography variant="h6">
                            On dirait que vous n'avez pas encore pris votre décision. Peut-être que vous devriez vous rendre sur notre{' '}
                            <a href="/" style={{ textDecoration: 'none' }}>Page d'accueil</a> ou...
                        </Typography>
                    </div>
                ) : (
                    <Grid container direction={{ md: 'column', lg: 'row', xl: 'row', sm: 'column', xs: 'column' }}>
                        <Grid container item xs={8} className="center-content" direction="column">
                            {cartItems.length === 0 && (
                                <div className="panier-vide-page">
                                    <ProductionQuantityLimitsIcon style={{ fontSize: 200, color: 'error' }} />
                                    <Typography variant="h5">Votre panier est vide</Typography>
                                </div>
                            )}
                            {cartItems.length > 0 && (
                                <>
                                    {cartItems.map((item) => (
                                        <Card key={item.panier_id} className="Card">
                                            <CardContent className="CardContent">
                                                <div className="CardImage">
                                                    <img
                                                        src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${item.image}`}
                                                        alt={item.nom}
                                                    />

                                                </div>
                                                <div>
                                                    <Typography variant="h5">
                                                        {item.nom}
                                                    </Typography>
                                                    <div className='button-container'>
                                                        <Tooltip title="Modifier" arrow>
                                                            <Button id="custom-button" color='blackbutton' variant="outlined" onClick={() => modifyCartItem(item)} disabled={isLoading}>
                                                                {isLoading ? (
                                                                    <CircularProgress size={25} color="inherit" />
                                                                ) : (
                                                                    <EditIcon />
                                                                )}
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Enregistrer" arrow>
                                                            <Button id="custom-button" color='blackbutton' variant="outlined" onClick={() => toggleEnregistred(item.panier_id, false)} disabled={isLoading}>
                                                                {isLoading ? (
                                                                    <CircularProgress size={25} color="inherit" />
                                                                ) : (
                                                                    <SaveIcon />
                                                                )}

                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Supprimer" arrow>
                                                            <Button id="custom-button" color='error' variant="outlined" onClick={() => deleteCartItem(item.panier_id, item.image_recto, item.image_verso)} disabled={isLoading}>
                                                                {isLoading ? (
                                                                    <CircularProgress size={25} color="inherit" />
                                                                ) : (
                                                                    <DeleteIcon />
                                                                )}

                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    <Accordion id='accordion-shoppingcart'>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        > <Typography>Détails du produit</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Largeur:</span> {item.largeur}</Typography>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Longueur:</span> {item.hauteur}</Typography>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Quantité:</span> {item.quantite}</Typography>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Mode impression:</span> {item.mode_impression}</Typography>
                                                                <Typography variant="body1" id="long-text"><span style={{ fontWeight: 'bold' }}>Image Recto:</span> {item.image_recto}</Typography>
                                                                <Typography variant="body1" id="long-text"><span style={{ fontWeight: 'bold' }}>Image Verso:</span> {item.image_verso}</Typography>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </>
                            )}
                        </Grid>
                        <Grid container item xs={4} className='button-grid-container' direction={'column'}>
                            <div className='div-button'>
                                <Button
                                    variant="contained"
                                    id="big-button"
                                    color='info'
                                    disabled={cartItems.length === 0 || isLoading || isFileExist}
                                    onClick={() => {
                                        const cartItemIds = cartItems.map(item => item.panier_id).join(',');
                                        navigate('/shipping', { state: { cartItemIds } });
                                    }}
                                >
                                    Valider mon panier
                                </Button>

                                <Button variant="contained" id="small-button" color='secondary' onClick={() => { window.location.href = '/' }} disabled={isLoading}>
                                    Poursuivre mes achats
                                </Button>
                            </div>
                        </Grid>
                        {savedItems.length > 0 && (
                            <Grid container className='grid-saved-cart' direction="column">
                                <div className="div-saved-cart">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }}>
                                        <h4>Enregistré pour plus tard</h4>
                                        <Button variant="text" color='primary' onClick={() => { navigate('/savedshoppingcart'); }}>
                                            Éditer
                                        </Button>
                                    </div>
                                    <hr />
                                    {savedItems.map((item) => (
                                        <Card key={item.panier_id} className="CardSaved">
                                            <CardContent className="CardContent">
                                                <div className="CardImage">
                                                    <img
                                                        src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${item.image}`}
                                                        alt={item.nom}
                                                    />

                                                </div>
                                                <div>
                                                    <Typography variant="h5">
                                                        {item.nom}
                                                    </Typography>
                                                    <Accordion >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        > <Typography>Détails du produit</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Largeur:</span> {item.largeur}</Typography>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Longueur:</span> {item.hauteur}</Typography>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Quantité:</span> {item.quantite}</Typography>
                                                                <Typography variant="body1"><span style={{ fontWeight: 'bold' }}>Mode impression:</span> {item.mode_impression}</Typography>
                                                                <Typography variant="body1" id="long-text-saved"><span style={{ fontWeight: 'bold' }}>Image Recto:</span> {item.image_recto}</Typography>
                                                                <Typography variant="body1" id="long-text-saved" style={{ wordWrap: 'break-word', maxWidth: '250px' }}><span style={{ fontWeight: 'bold' }}>Image Verso:</span> {item.image_verso}</Typography>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <div className='button-container'>
                                                        <Button variant="text" color='primary' onClick={() => toggleEnregistred(item.panier_id, true)}>
                                                            {isLoading ? (
                                                                <CircularProgress size={25} color="inherit" />
                                                            ) : (
                                                                'Ajouter au panier'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                )}
                <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            boxShadow: 24,
                        }}
                    >
                        <Alert severity="error">
                            <AlertTitle style={{ fontSize: '1.5rem' }}>Erreur</AlertTitle>
                            <Typography variant="body1" style={{ fontSize: '1rem' }}>
                                Vous ne pouvez pas ajouter plus d'un produit dans le panier
                            </Typography>
                        </Alert>
                    </Box>
                </Modal>
            </Container>
        </Layout>

    );
}

export default ShoppingCart;