import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from '../../Components/axios';
import { useNavigate } from 'react-router-dom';

// Components
import Layout from '../../Components/Layout/Layout';

// Contexts
import { AuthContext } from '../../Components/context/AuthContext';
import { ShoppingCartContext } from '../../Components/context/ShoppingCartContext';

// MUI Components
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Modal,
    Box,
    Alert,
    AlertTitle,
    CircularProgress
} from '@mui/material';

// MUI Icons
import SaveIcon from '@mui/icons-material/Save';

import './_savedshoppingcart.scss';

const Savedshoppingcart = () => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const [shoppingCarts, setShoppingCarts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { currentUserId } = useContext(AuthContext);
    const { updateNumberOfProducts } = useContext(ShoppingCartContext);
    const [isLoading, setIsLoading] = useState(false);
    const modify = "savedPage";
    const navigate = useNavigate();

    const fetchShoppingCarts = useCallback(async () => {
        const userId = currentUserId;
        axios.get(`/getShoppingCart/enregistred/${userId}`)
            .then(response => {
                setShoppingCarts(response.data.shoppingCartDetails);
            })
            .catch(error => {
                console.error('Une erreur s\'est produite lors de la récupération des paniers enregistrés:', error);
            });
    }, [currentUserId]);

    useEffect(() => {
        fetchShoppingCarts();
    }, [fetchShoppingCarts]);

    const toggleEnregistred = async (itemId) => {
        try {
            const notEnregistredCartResponse = await axios.get(`/getShoppingCart/notenregistred/${currentUserId}`);
            const notEnregistredCart = notEnregistredCartResponse.data.products;

            if (notEnregistredCart.length === 0) {
                const confirmation = window.confirm('Voulez-vous vraiment ajouter ce produit au panier ?');

                if (confirmation) {
                    setIsLoading(true);
                    await axios.get(`/toggle/${itemId}`);
                    setIsLoading(false);
                    fetchShoppingCarts();
                    updateNumberOfProducts();
                }
            } else {
                setIsLoading(false);
                setShowModal(true);
            }
        } catch (error) {
            console.error('Erreur lors du basculement de l\'enregistrement', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const modifySavedCartItem = (product) => {
        navigate('/product', { state: { product, modify } });
    };

    const deleteSavedCartItem = async (itemId, image_recto, image_verso) => {
        try {
            const confirmation = window.confirm('Voulez-vous vraiment supprimer ce produit sauvegardé ?');

            if (confirmation) {
                setIsLoading(true);
                await axios.delete(`/deleteShoppingCart/${itemId}`);
                if (image_recto) {
                    const rectoWithoutExtension = image_recto.replace(/\.[^/.]+$/, "");
                    await deleteCloudinaryFile(rectoWithoutExtension);
                }
                if (image_verso) {
                    const versoWithoutExtension = image_verso.replace(/\.[^/.]+$/, "");
                    await deleteCloudinaryFile(versoWithoutExtension);
                }
                setIsLoading(false);
                fetchShoppingCarts();
                updateNumberOfProducts();
            }
        } catch (error) {
            console.error('Erreur lors de la suppression du produit', error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const deleteCloudinaryFile = async (publicId) => {
        try {
            const response = await axios.delete(`/product/file/${publicId}`);

            if (response.status === 200) {
            } else {
                console.error('Error deleting file from backend:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting file from backend:', error.message);
        }
    };

    return (
        <Layout>
            <Container maxWidth="lg" id='container-savedshoppingcart'>
                <h1 className='title-savedshoppingcart'>Sauvegardé pour plus tard</h1>
                {shoppingCarts.length === 0 ? (
                    <div className="panier-vide-page">
                        <SaveIcon style={{ fontSize: 200 }} />
                        <Typography variant="h5">Aucun article sauvegardé</Typography>
                        <Typography variant="h6">
                            Il semble que vous n'ayez pas encore sauvegardé d'article.
                        </Typography>
                    </div>
                ) : (
                    <Grid container direction={'row'} className='Grid-savedshoppingcart'>
                        {shoppingCarts.map(cart => (
                            <Card key={cart.panier_id} className="Card-savedshoppingcart">
                                <CardContent className="CardContent-savedshoppingcart">
                                    <div>
                                        <img
                                            src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${cart.image}`}
                                            alt={cart.nom}
                                        />

                                    </div>
                                    <div className='text-savedshoppingcart'>
                                        <Typography variant="h5" id="title-savedshoppingcart">
                                            {cart.nom}
                                        </Typography>
                                        <Typography variant="body" color="text.secondary">
                                            Ajouté le: <strong> {new Date(cart.date_heure_ajout).toLocaleDateString('fr-FR')} </strong>
                                        </Typography>
                                        <div className='button-savedshoppingcart'>
                                            <Button variant="contained" color="primary" id="cardButton-savedshoppingcart" onClick={() => toggleEnregistred(cart.panier_id)} disabled={isLoading}>
                                                {isLoading ? (
                                                    <CircularProgress size={25} color="inherit" />
                                                ) : (
                                                    'Ajouter au panier'
                                                )}
                                            </Button>
                                            <Button variant="contained" color="secondary" id="cardButton-savedshoppingcart" onClick={() => modifySavedCartItem(cart)} disabled={isLoading}>
                                                {isLoading ? (
                                                    <CircularProgress size={25} color="inherit" />
                                                ) : (
                                                    'Modifier des options ou quantité'
                                                )}
                                            </Button>
                                            <Button variant="contained" color="error" id="cardButton-savedshoppingcart" onClick={() => deleteSavedCartItem(cart.panier_id, cart.image_recto, cart.image_verso)} disabled={isLoading}>
                                                {isLoading ? (
                                                    <CircularProgress size={25} color="inherit" />
                                                ) : (
                                                    'Supprimer'
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </Grid>
                )}
                <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            boxShadow: 24,
                        }}
                    >
                        <Alert severity="error">
                            <AlertTitle style={{ fontSize: '1.5rem' }}>Erreur</AlertTitle>
                            <Typography variant="body1" style={{ fontSize: '1rem' }}>
                                Vous ne pouvez pas ajouter plus d'un produit dans le panier
                            </Typography>
                        </Alert>
                    </Box>
                </Modal>
            </Container>
        </Layout>
    );
};

export default Savedshoppingcart;