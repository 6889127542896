import { createContext, useState, useCallback, useEffect } from "react";
import axios from "../axios";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentUserRole, setCurrentUserRole] = useState(null);
    const [currentUserEmail, setCurrentUserEmail] = useState(null);

    const login = async (values) => {
        try {
            const res = await axios.post("/login", values);
            const { username, utilisateur_id, user_role, user_email, token } = res.data;

            if (token) {
                localStorage.setItem('token', token);
                setCurrentUser(username);
                setCurrentUserId(utilisateur_id);
                setCurrentUserRole(user_role);
                setCurrentUserEmail(user_email);
            }

            await updateLastLogin(utilisateur_id);

            return res;
        } catch (error) {
            throw error;
        }
    };

    const logout = useCallback(async () => {
        try {
            const username = currentUser;
            const res = await axios.post("/logout", { username });
            setCurrentUser(null);
            setCurrentUserId(null);
            setCurrentUserRole(null);
            setCurrentUserEmail(null);
            localStorage.removeItem('token');
            delete axios.defaults.headers.common["Authorization"];
            return res;
        } catch (error) {
            throw error;
        }
    }, [currentUser]);

    const verifyUser = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');

            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            const res = await axios.post("/verifyUser");
            const { Status, username, utilisateur_id, user_role, user_email } = res.data;

            if (Status === 'Unauthorized') {
                setCurrentUser(null);
                setCurrentUserId(null);
                setCurrentUserRole(null);
                setCurrentUserEmail(null);
                localStorage.removeItem('token');
                delete axios.defaults.headers.common["Authorization"];
                return res;
            }

            setCurrentUser(username);
            setCurrentUserId(utilisateur_id);
            setCurrentUserRole(user_role);
            setCurrentUserEmail(user_email);

            return res;
        } catch (error) {
            throw error;
        }
    }, [setCurrentUser, setCurrentUserId, setCurrentUserRole, setCurrentUserEmail]);

    const updateLastLogin = async (currentUserIdFromLogin) => {
        try {
            const token = localStorage.getItem('token');

            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            await axios.put(`/updateLastLogin/${currentUserIdFromLogin}`);
        } catch (error) {
            console.error("Erreur lors de la mise à jour de la date de connexion :", error);
        }
    };

    const checkUserStatus = useCallback(async () => {
        try {
            const resVerify = await verifyUser();

            if (resVerify.data.Status === "Success") {
            }
            else {
                await logout();
                window.location.replace("/login");
            }
        } catch (error) {
            await logout();
            window.location.replace("/login");
        }
    }, [verifyUser, logout]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            checkUserStatus();
        }, 300000);

        return () => clearInterval(intervalId);
    }, [currentUser, currentUserId, checkUserStatus]);

    return (
        <AuthContext.Provider value={{ currentUser, currentUserId, currentUserRole, currentUserEmail, login, logout, verifyUser }}>
            {children}
        </AuthContext.Provider>
    );
};