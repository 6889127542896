import React, { useState, useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import { Dialog, DialogTitle, DialogContent, TextField, Button, FormControlLabel, RadioGroup, Radio, CircularProgress } from '@mui/material';

const UpdateUser = ({ user, onSave, onClose }) => {
    const { currentUserId } = useContext(AuthContext);
    const isEditMode = !!user;
    const [formData, setFormData] = useState({
        utilisateur_id: user?.utilisateur_id || '',
        nom: user?.nom || '',
        prenom: user?.prenom || '',
        nom_utilisateur: user?.nom_utilisateur || '',
        mail: user?.mail || '',
        telephone: user?.telephone || '',
        telephone_fixe: user?.telephone_fixe || '',
        user_role: user?.user_role || '',
        mot_de_passe: '',
        confirmer_mot_de_passe: '',
    });

    const [errors, setErrors] = useState({
        nom: false,
        prenom: false,
        nom_utilisateur: false,
        mail: false,
        mot_de_passe: false,
        confirmer_mot_de_passe: false,
    });

    const [isModified, setIsModified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setIsModified(true);
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        ['nom', 'prenom', 'nom_utilisateur', 'mail'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                isValid = false;
            }
        });

        if (formData.telephone && !/^\d{10}$/.test(formData.telephone)) {
            newErrors.telephone = true;
            isValid = false;
        }

        if (formData.telephone_fixe && !/^\d{10}$/.test(formData.telephone_fixe)) {
            newErrors.telephone_fixe = true;
            isValid = false;
        }

        if (!isEditMode) {
            if (!formData.mot_de_passe) {
                newErrors.mot_de_passe = true;
                isValid = false;
            }

            if (!formData.confirmer_mot_de_passe) {
                newErrors.confirmer_mot_de_passe = true;
                isValid = false;
            }

            if (formData.mot_de_passe !== formData.confirmer_mot_de_passe) {
                newErrors.confirmer_mot_de_passe = true;
                isValid = false;
            }
        }
        else {
            if (formData.mot_de_passe !== '' || formData.confirmer_mot_de_passe !== '') {
                if (!formData.mot_de_passe) {
                    newErrors.mot_de_passe = true;
                    isValid = false;
                }

                if (!formData.confirmer_mot_de_passe) {
                    newErrors.confirmer_mot_de_passe = true;
                    isValid = false;
                }

                if (formData.mot_de_passe !== formData.confirmer_mot_de_passe) {
                    newErrors.confirmer_mot_de_passe = true;
                    isValid = false;
                }
            }
        }

        if (!user && !formData.user_role) {
            newErrors.user_role = true;
            isValid = false;
        }

        const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (formData.mail && !emailRegex.test(formData.mail)) {
            newErrors.mail = true;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSave = async () => {
        if (validateForm()) {
            const isEditMode = !!user;
            const action = isEditMode ? 'modifier' : 'ajouter';

            const confirmationMessage = `Êtes-vous sûr de vouloir ${action} cet utilisateur ?`;

            const isConfirmed = window.confirm(confirmationMessage);

            if (isConfirmed) {
                setIsLoading(true);

                try {

                    onSave(formData, isEditMode);
                    setIsModified(true);
                } catch (error) {
                    console.error('Error saving user:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const handleCancel = () => {
        onClose();
    };


    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>{isEditMode ? 'Modifier l\'utilisateur' : 'Ajouter un utilisateur'}</DialogTitle>
            <DialogContent sx={{ margin: '20px', padding: '20px' }}>
                <form id='form-control-user'>
                    <TextField
                        label="Nom"
                        name="nom"
                        value={formData.nom || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.nom}
                        helperText={errors.nom && 'Le nom est requis'}
                    />
                    <TextField
                        label="Prénom"
                        name="prenom"
                        value={formData.prenom || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.prenom}
                        helperText={errors.prenom && 'Le prénom est requis'}
                    />
                    <TextField
                        label="Nom d'utilisateur"
                        name="nom_utilisateur"
                        value={formData.nom_utilisateur || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.nom_utilisateur}
                        helperText={errors.nom_utilisateur && 'Le nom d\'utilisateur est requis'}
                    />
                    <TextField
                        label="Email"
                        name="mail"
                        value={formData.mail || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.mail}
                        helperText={errors.mail && 'Veuillez fournir une adresse e-mail valide'}
                    />
                    <TextField
                        label="Téléphone"
                        name="telephone"
                        value={formData.telephone || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.telephone}
                        helperText={errors.telephone && 'Veuillez saisir un numéro de téléphone valide (10 chiffres).'}
                    />
                    <TextField
                        label="Téléphone fixe"
                        name="telephone_fixe"
                        value={formData.telephone_fixe || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.telephone_fixe}
                        helperText={errors.telephone_fixe && 'Veuillez saisir un numéro de téléphone fixe valide (10 chiffres).'}
                    />
                    <TextField
                        type="password"
                        label="Mot de passe"
                        name="mot_de_passe"
                        value={formData.mot_de_passe || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.mot_de_passe}
                        helperText={errors.mot_de_passe && 'Le mot de passe est requis'}
                    />
                    <TextField
                        type="password"
                        label="Confirmer le mot de passe"
                        name="confirmer_mot_de_passe"
                        value={formData.confirmer_mot_de_passe || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.confirmer_mot_de_passe}
                        helperText={errors.confirmer_mot_de_passe && 'Les nouveaux mots de passe ne correspondent pas'}
                    />

                    <RadioGroup row aria-label="user_role" name="user_role" value={formData.user_role} onChange={handleChange}>
                        <FormControlLabel value="administrateur" control={<Radio />} label="Administrateur" />
                        <FormControlLabel value="utilisateur" control={<Radio />} label="Utilisateur" />
                    </RadioGroup>

                    {errors.user_role && (
                        <div className="alert mt-3 alert-danger">
                            La sélection du rôle est obligatoire.
                        </div>
                    )}

                    {formData.utilisateur_id === currentUserId && (
                        <div className="alert mt-3 alert-warning">
                            Si vous modifiez vos informations, merci de vous déconnecter puis vous reconnecter.
                        </div>
                    )}

                    <Button variant="contained" color="secondary" onClick={handleCancel} fullWidth sx={{ marginTop: '20px' }} disabled={isLoading}>
                        {isLoading ? (
                            <CircularProgress size={26} color="inherit" />
                        ) : (
                            'Annuler'
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        fullWidth
                        sx={{ marginTop: '10px' }}
                        disabled={!isModified || isLoading}
                    >
                        {isLoading ? (
                            <CircularProgress size={26} color="inherit" />
                        ) : (
                            'Enregistrer'
                        )}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateUser;