import { Box } from '@mui/material';
import React from 'react';

const VerifImage = ({ rectoImage, versoImage }) => {
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const cloudinaryBaseUrl = `https://res.cloudinary.com/${cloudName}/image/upload/`;
  const folderPath = 'gobilab/panier/';

  const getCloudinaryUrl = (publicId) => {
    return `${cloudinaryBaseUrl}${folderPath}${publicId}`;
  };

  return (
    <div>
      <h1>Vérification d'Image</h1>
      <Box sx={{ color: 'warning.main' }}>Si vos fichiers ne s'affichent pas, ne vous inquiétez pas - ils ont bien été pris en compte.</Box>
      {rectoImage && (
        <div>
          <h2>Image Recto du Produit</h2>
          <iframe
            title="Recto"
            width="100%"
            height="600px"
            src={getCloudinaryUrl(rectoImage)}
            allowFullScreen
          />
        </div>
      )}
      {versoImage && (
        <div>
          <h2>Image Verso du Produit</h2>
          <iframe
            title="Verso"
            width="100%"
            height="600px"
            src={getCloudinaryUrl(versoImage)}
            allowFullScreen
          />
        </div>
      )}
    </div>
  );
};

export default VerifImage;