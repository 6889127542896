import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, CircularProgress } from '@mui/material';
import axios from './axios';

const UpdateProduct = ({ product, onSave, onClose }) => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

    const isEditMode = !!product;
    const [formData, setFormData] = useState({
        produit_id: product?.produit_id || '',
        nom: product?.nom || '',
        largeur: product?.largeur || '',
        hauteur: product?.hauteur || '',
        image: product?.image || '',
        repertoire: product?.repertoire || '',
    });

    const [errors, setErrors] = useState({
        nom: false,
        largeur: false,
        hauteur: false,
        image: false,
        repertoire: false,
    });

    const [imageURL, setImageURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [oldImagePublicId, setOldImagePublicId] = useState('');
    const [newImagePublicId, setNewImagePublicId] = useState('');

    useEffect(() => {
        if (product && product.image) {
            const imageURL = `https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${product.image}`;

            setImageURL(imageURL);
            setOldImagePublicId(product.image);
        }
    }, [product, cloudName]);

    const handleFileChange = async () => {
        const uploadPreset = 'ml_default';
        const apiUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

        const fileInput = document.getElementById('image-input-edit-create');
        const file = fileInput.files[0];

        if (!file) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: true,
            }));
            return;
        }

        if (!file.type.startsWith('image/')) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: true,
            }));
            return;
        }
        const folder = 'gobilab/illustrations';

        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', uploadPreset);
        formData.append('public_id', file.name.split('.').slice(0, -1).join('.'));
        formData.append('folder', folder);

        try {
            setIsLoading(true);

            if (newImagePublicId) {
                await deleteCloudinaryImage(newImagePublicId);
            }

            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                const imageURL = result.secure_url;

                setFormData((prevData) => ({
                    ...prevData,
                    image: file.name,
                }));
                setImageURL(imageURL);
                setNewImagePublicId(file.name.split('.').slice(0, -1).join('.'));

                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: false,
                }));
            } else {
                console.error('Error uploading image:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        ['nom', 'largeur', 'hauteur', 'image', 'repertoire'].forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = true;
                isValid = false;
            }
        });

        if (formData.largeur && isNaN(Number(formData.largeur))) {
            newErrors.largeur = true;
            isValid = false;
        }

        if (formData.hauteur && isNaN(Number(formData.hauteur))) {
            newErrors.hauteur = true;
            isValid = false;
        }

        if (!formData.image) {
            newErrors.image = true;
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSave = async () => {
        if (validateForm()) {
            const action = isEditMode ? 'modifier' : 'ajouter';
            const confirmationMessage = `Êtes-vous sûr de vouloir ${action} ce produit ?`;
            const isConfirmed = window.confirm(confirmationMessage);

            if (isConfirmed) {
                setIsLoading(true);

                try {
                    if (oldImagePublicId) {
                        await deleteCloudinaryImage(oldImagePublicId);
                    }

                    const newImageFileName = formData.image.split('.').slice(0, -1).join('.');

                    const formDataWithNewImage = {
                        ...formData,
                        image: newImageFileName,
                    };

                    await onSave(formDataWithNewImage, isEditMode);
                } catch (error) {
                    console.error('Error saving product:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        }
    };

    const deleteCloudinaryImage = async (publicId) => {
        try {
            const response = await axios.delete(`/product/image/${publicId}`);

            if (response.status === 200) {
            } else {
                console.error('Error deleting image from backend:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting image from backend:', error.message);
        }
    };

    const handleCancel = () => {
        if (newImagePublicId) {
            deleteCloudinaryImage(newImagePublicId);
        }
        onClose(newImagePublicId);
    };

    return (
        <Dialog open={true} onClose={handleCancel}>
            <DialogTitle>{isEditMode ? 'Modifier le produit' : 'Ajouter un produit'}</DialogTitle>
            <DialogContent sx={{ margin: '20px', padding: '20px' }}>
                <form id='form-control-product'>
                    <TextField
                        label="Nom du produit"
                        name="nom"
                        value={formData.nom || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.nom}
                        helperText={errors.nom && 'Le nom du produit est requis'}
                    />
                    <TextField
                        label="Largeur"
                        name="largeur"
                        value={formData.largeur || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.largeur}
                        helperText={errors.largeur && 'La largeur est requise et doit être un nombre'}
                    />
                    <TextField
                        label="Hauteur"
                        name="hauteur"
                        value={formData.hauteur || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.hauteur}
                        helperText={errors.hauteur && 'La hauteur est requise et doit être un nombre'}
                    />
                    <TextField
                        label="Répertoire"
                        name="repertoire"
                        value={formData.repertoire || ''}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={errors.repertoire}
                        helperText={errors.repertoire && 'Le répertoire est requis'}
                        placeholder="gobilab/commande/gobirectangle"
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <label htmlFor="image-input-edit-create">
                            <Button variant="outlined" component="span" color="primary" fullWidth sx={{ marginTop: '10px' }}>
                                Sélectionner une image
                            </Button>
                        </label>
                        {errors.image && (
                            <div style={{ color: 'red', marginTop: '5px' }}>
                                L'image est requise
                            </div>
                        )}
                        {formData.image && <img src={imageURL} alt="Selected" style={{ maxWidth: '100%', marginTop: '10px' }} />}
                        <input type="file" id="image-input-edit-create" onChange={handleFileChange} style={{ display: 'none' }} accept="image/*" />
                    </div>
                    <Button variant="contained" color="secondary" onClick={handleCancel} fullWidth sx={{ marginTop: '20px' }} disabled={isLoading}>
                        {isLoading ? (
                            <CircularProgress size={26} color="inherit" />
                        ) : (
                            'Annuler'
                        )}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={isLoading}
                        fullWidth
                        sx={{ marginTop: '10px' }}
                    >
                        {isLoading ? (
                            <CircularProgress size={26} color="inherit" />
                        ) : (
                            'Enregistrer'
                        )}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateProduct;