import React, { useState, useEffect, useContext } from 'react';
import axios from '../../Components/axios';
import { useNavigate } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import { AuthContext } from '../../Components/context/AuthContext';
import {
    Container,
    Grid,
    Button,
    TextField,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import './_checkout.scss';

const Checkout = () => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const [userCart, setUserCart] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const { currentUserId, currentUserEmail } = useContext(AuthContext);
    const [reference, setReference] = useState('');
    const [comment, setComment] = useState('');
    const [isOrderSuccessModalOpen, setOrderSuccessModalOpen] = useState(false);
    const [referenceError, setReferenceError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handleOrderCreation = async () => {
        try {
            if (!reference.trim()) {
                setReferenceError('La référence de la commande est requise.');
                return;
            }

            const confirmOrder = window.confirm('Êtes-vous sûr de vouloir passer cette commande ?');

            if (!confirmOrder) {
                return;
            }

            setIsLoading(true);

            const orderData = {
                reference: reference,
                userId: currentUserId,
                userEmail: currentUserEmail,
                statut: 'Order Received',
                adresse_id: selectedAddress.adresse_id,
                commentaire: comment,
                optionLivraison: selectedOption,
                            };
            await axios.post('/order', orderData);

            setReferenceError('');
            setOrderSuccessModalOpen(true);

            setIsLoading(false);

        } catch (error) {
            console.error('Erreur lors de la création de la commande :', error);
            setIsLoading(false);
        }
    };


    const handleCloseOrderSuccessModal = () => {
        setOrderSuccessModalOpen(false);
        window.location.href = '/'
    };

    useEffect(() => {
        const fetchUserCart = async () => {
            try {
                const userId = currentUserId;
                const response = await axios.get(`getAllShoppingCart/user/${userId}`);
                setUserCart(response.data.products);

                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const addressParam = urlParams.get('address');
                const optionParam = urlParams.get('option');

                if (addressParam && optionParam) {
                    try {
                        setSelectedAddress(JSON.parse(decodeURIComponent(addressParam)));
                        setSelectedOption(optionParam);
                    } catch (error) {
                        console.error('Error parsing address or option parameter:', error);
                    }
                } else {
                    window.location.href = '/shipping';
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du panier de l\'utilisateur', error);
            }
        };

        fetchUserCart();
    }, [currentUserId]);

    return (
        <Layout>
            <div className='div-checkout'>
                <Container className="container-checkout" maxWidth='lg'>
                    <h2 className='title-checkout'>Vérification</h2>
                    <Grid container direction="column" alignItems="center">
                        <FormControl fullWidth variant="outlined" id='form-control-checkout'>
                            <TextField
                                color='primary'
                                id="outlined-input"
                                label="Référence de la commande"
                                fullWidth
                                name='reference'
                                variant='outlined'
                                value={reference}
                                onChange={(e) => {
                                    setReference(e.target.value);
                                    setReferenceError('');
                                }}
                                error={Boolean(referenceError)}
                                helperText={referenceError}
                            />
                        </FormControl>

                        <FormControl fullWidth variant="outlined" id='form-control-checkout'>
                            <label>Commentaires (facultatif)</label>
                            <textarea name='comment'
                                className='textarea'
                                rows="3" 
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}>
                            </textarea>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="info"
                            id="button-checkout"
                            onClick={handleOrderCreation}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress color="info" size={42} /> : 'Commander'}
                        </Button>
                        <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
                            <h3>Livraison</h3>
                            <Button variant="text" color='primary' onClick={() => {
                                navigate('/shipping', { state: "modify" });
                            }}>
                                Éditer
                            </Button>
                        </Grid>
                        <Grid container direction="column" className='grid-checkout'>
                            <div>
                                {userCart
                                    .filter((product) => product.est_enregistre === 0)
                                    .map((product) => (
                                        <div className='product-checkout' key={product.produit_id} style={{ marginBottom: '20px' }}>
                                            <img
                                                src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${product.image}`}
                                                alt={product.nom}
                                                className='image-checkout'
                                            />
                                            <span>{product.nom}</span>
                                        </div>
                                    ))}
                            </div>
                            {selectedAddress && (
                                <div>
                                    <div className="addressContainer">
                                        <hr className="hrStyle" />
                                        <span style={{ padding: '0 10px' }}>Livraison à</span>
                                        <hr className="hrStyle" />
                                    </div>
                                    <div className="deliveryAddress">
                                        <p className="deliveryInfo">{selectedAddress.entreprise}</p>
                                        <p className="addressInfo">
                                            {selectedAddress.adresse},<br />
                                            {selectedAddress.ville}, {selectedAddress.departement_region} {selectedAddress.abreviation} {selectedAddress.code_postal}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Dialog
                open={isOrderSuccessModalOpen}
                onClose={handleCloseOrderSuccessModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Commande créée avec succès !"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Votre commande a été enregistrée avec succès.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseOrderSuccessModal} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};

export default Checkout;