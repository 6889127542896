import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axios';
import { AuthContext } from './AuthContext';

export const ShoppingCartContext = createContext();

export const ShoppingCartProvider = ({ children }) => {
    const [numberOfProducts, setNumberOfProducts] = useState(0);
    const { currentUserId } = useContext(AuthContext);


    const fetchNumberOfProductsInShoppingCart = async (currentUserId) => {
        try {
            const response = await axios.get(`/shoppingcartnumber/${currentUserId}`);
            setNumberOfProducts(response.data.numberOfProduct);
        } catch (error) {
            console.error('Erreur lors de la récupération des produits :', error);
        }
    };

    useEffect(() => {
        if (currentUserId) {
            fetchNumberOfProductsInShoppingCart(currentUserId);
        }
    }, [currentUserId]);

    const updateNumberOfProducts = async () => {
        if (currentUserId) {
            await fetchNumberOfProductsInShoppingCart(currentUserId);
        }
    };

    return (
        <ShoppingCartContext.Provider value={{ numberOfProducts, updateNumberOfProducts }}>
            {children}
        </ShoppingCartContext.Provider>
    );
};