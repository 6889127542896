import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Components/context/AuthContext';
import axios from '../../Components/axios';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Layout from '../../Components/Layout/Layout';
import UpdateProduct from '../../Components/UpdateProduct';
import './_homeandlayout.scss';

const Home = () => {
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
  const [products, setProducts] = useState([]);
  const { currentUserRole, logout } = useContext(AuthContext);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContentText, setDialogContentText] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/getAllProducts');
        setProducts(response.data.products);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          const res = await logout();
          if (res.data.Status === 'deconnected') {
            navigate('/login');
          } else {
            alert(res.data.Error);
          }
        } else {
          console.error('Erreur lors de la récupération des produits :', error);
        }
      }
    };
    fetchProducts();
  }, [logout, navigate]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    window.location.reload();
  };

  const handleAddProductClick = () => {
    handleEditOrCreateClick(null);
  };

  const handleEditOrCreateClick = (product) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  };

  const handleCloseDialog = async (newImagePublicId) => {
    if (newImagePublicId) {
      deleteCloudinaryImage(newImagePublicId);
    }
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const deleteCloudinaryImage = async (publicId) => {
    try {
      const response = await axios.delete(`/product/image/${publicId}`);

      if (response.status === 200) {
      } else {
        console.error('Error deleting image from backend:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting image from backend:', error.message);
    }
  };

  const handleSaveProduct = async (formData, isEditMode) => {
    try {

      const formDataWithoutImage = { ...formData };

      if (isEditMode) {
        await axios.put('/updateProduct', formDataWithoutImage);
        setDialogTitle("Produit modifié");
        setDialogContentText("Les informations du produit ont été modifiées avec succès.");
      } else {
        await axios.post('/product', formDataWithoutImage);
        setDialogTitle("Nouveau produit enregistré");
        setDialogContentText("Un nouveau produit a été enregistré avec succès.");
      }

      setIsDialogOpen(true);
    } catch (error) {
      console.error('Error updating/registering product:', error.message);
    }
  };

  return (
    <Layout>
      <div className='home-page'>
        <div className='product-cards-home'>
          {products.map((product) => (
            <Card className='product-card-home' key={product.produit_id}>
              {product.image && (
                <Link to="/product" state={{ product: product }}>
                  <img className='img-home' src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${product.image}`} alt={product.nom} />
                </Link>
              )}
              <CardContent>
                <Typography variant="h5" component="div">
                  {product.nom}
                </Typography>
                <div className="button-container-home">
                  <Link to="/product" state={{ product: product }}>
                    <Button variant="contained" className="button-home" color='info'>
                      Accéder au produit
                    </Button>
                  </Link>
                </div>
              </CardContent>
            </Card>
          ))}
          {currentUserRole === 'administrateur' && (
            <Card className='product-card-home'>
              <div onClick={handleAddProductClick} className='addProduct-home'>
                <img className='img-home' src={"/images/Add_Icon.png"} alt="Ajout produit" />
              </div>
              <CardContent>
                <Typography variant="h5" component="div">
                  Ajouter un produit
                </Typography>
                <Button variant="contained" className="button-home" color='info' onClick={() => handleEditOrCreateClick(null)}>
                  Ajouter un produit
                </Button>
              </CardContent>
            </Card>
          )}
        </div>
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogContentText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogContent>
            <UpdateProduct product={selectedProduct} onSave={handleSaveProduct} onClose={handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </div>
    </Layout>
  );
};

export default Home;