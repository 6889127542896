import React from 'react';
import { Navigate } from 'react-router-dom';
import { Container, Grid, Paper } from '@mui/material';
import Layout from '../../Components/Layout/Layout';
import './_orderdetails.scss'

const OrderDetails = () => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const urlParams = new URLSearchParams(window.location.search);
    const commande_id = urlParams.get('commande_id');
    const reference = urlParams.get('reference');
    const date_commande = urlParams.get('date_commande');
    const statut = urlParams.get('statut');
    const quantite = urlParams.get('quantite');
    const mode_impression = urlParams.get('mode_impression');
    const image_recto = urlParams.get('image_recto');
    const image_verso = urlParams.get('image_verso');
    const commentaire = urlParams.get('commentaire');
    const product_name = urlParams.get('product_name');
    const product_image = urlParams.get('product_image');
    const product_largeur = urlParams.get('product_largeur');
    const product_hauteur = urlParams.get('product_hauteur');
    const address = urlParams.get('address');
    const entreprise = urlParams.get('entreprise');
    const ville = urlParams.get('ville');
    const departement_region = urlParams.get('departement_region');
    const code_postal = urlParams.get('code_postal');
    const user_fullname = urlParams.get('user_fullname');
    const user_mail = urlParams.get('user_mail');
    const option_livraison = urlParams.get('option_livraison');

    let commentaireFinal = '';  // Déplacez la déclaration à l'extérieur du bloc if-else

    if (commentaire !== null) {
        commentaireFinal = commentaire !== 'null' ? commentaire : '';
    } else {
        commentaireFinal = '';
    }

    const formatDateTime = (dateTimeString, includeTime = true) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        };

        if (!includeTime) {
            delete options.hour;
            delete options.minute;
        }

        const formattedDate = new Date(dateTimeString).toLocaleDateString('fr-FR', options);
        return formattedDate;
    };

    if (!commande_id) {
        return <Navigate to="/order" />;
    }

    return (
        <Layout>
            <Container maxWidth="xl" id='container-orderdetails'>
                <h1>Commande # {commande_id}</h1>
                <div className='first-grid-orderdetails'>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                        <Grid item xs={6} className="hide-hr">
                            <p>
                                <strong>Référence de la commande:</strong>
                                <span className="value-right">{reference}</span>
                            </p>
                            <hr />
                            <p>
                                <strong>Date de la commande:</strong>
                                <span className="value-right">{formatDateTime(date_commande)}</span>
                            </p>
                            <hr />
                            <p><strong>Note: </strong></p>
                            <p className='multiline-email'>{commentaireFinal}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <p><strong>Facturer à</strong></p>
                            <ul>
                                <li> {entreprise} </li>
                                <li> {address} </li>
                                <li> {ville}, {departement_region} {code_postal} </li>
                            </ul>

                            <p><strong>Commandé par</strong></p>
                            <ul>
                                <li> {user_fullname} </li>
                                <li className='multiline-email'> {user_mail} </li>
                            </ul>
                        </Grid>
                    </Grid>
                </div>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} textAlign={'center'} marginTop={'10px'}>
                        <h3>{product_name}</h3>
                    </Grid>
                    <Grid item xs={12} className='image-grid-orderdetails'>
                        <img
                            src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${product_image}`}
                            alt={product_name}
                            className='img-orderdetails'
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className='grid-section'>
                            <h2>Détails</h2>
                            <ul>
                                <li> Quantité d'exemplaires = <strong>{quantite}</strong> </li>
                                <li> Largeur = <strong>{product_largeur}</strong> </li>
                                <li> Hauteur = <strong>{product_hauteur}</strong> </li>
                                <li> Impression = <strong>{mode_impression}</strong> </li>
                            </ul>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className='grid-section'>
                            <h2>Méthode de livraison</h2>
                            <ul>
                                <li> Nom: <strong>{option_livraison}</strong> </li>
                            </ul>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className='grid-section'>
                            <h2>Date de livraison prévue</h2>
                            <p> {formatDateTime(date_commande, false)} </p>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className='grid-section'>
                            <h2>Livrer à</h2>
                            <ul>
                                <li> {entreprise} </li>
                                <li> {address} </li>
                                <li> {ville}, {departement_region} {code_postal} </li>
                            </ul>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className='grid-section'>
                            <h2>Statut</h2>
                            <p>{formatDateTime(date_commande)}</p>
                            <p> {statut} </p>
                            <p className='multiline-email'>
                                {user_fullname} &lt;{user_mail}&gt;
                            </p>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} >
                        <Paper className='grid-section'>
                            <h2>Fichier</h2>
                            <ul>
                                <li> {image_recto} </li>
                                {image_verso === "null" ? null : <li>{image_verso}</li>}
                            </ul>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
};

export default OrderDetails;