import React from 'react';
import './_notfound.scss';

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <div className='card-notfound'>
        <h1>Erreur 404</h1>
        <p>La page que vous avez demandée n'existe pas.</p>
        <p>Veuillez vérifier l'URL et réessayer.</p>
        <p>
          <a href="/">Cliquez ici pour retourner à l'accueil</a>
        </p>
      </div>
    </div>
  );
};
export default NotFoundPage;