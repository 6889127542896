import React, { useContext, useState, useEffect } from 'react';
import axios from '../../Components/axios';
import { useNavigate, Link } from 'react-router-dom';
import {
    Button,
    Container,
    FormControl,
    TextField,
    Typography,
    Grid,
    CircularProgress
} from '@mui/material';
import { AuthContext } from '../../Components/context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './_login.scss';

const Login = () => {
    const [values, setValues] = useState({
        usernameormail: '',
        password: '',
    });

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const { login, currentUser } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            setIsLoading(true);
            const res = await login(values);
            if (res.data.Status === 'Success') {
                setIsLoading(false);
                navigate('/');
            } else {
                setIsLoading(false);
                setError(res.data.Error);
            }
        } catch (err) {
            console.error(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token !== null && token !== undefined) {
            navigate('/');
        }
    }, [currentUser, navigate]);

    return (
        <Container maxWidth='lg'>
            <Grid container direction="column" className='grid-login' >
                <a href="/login">
                    <img src='/images/gobiLAB_logo.png' alt="Logo" className='logo-login' />
                </a>
                <Grid className="login-form">
                    <Typography id='connexion-text' component="h1" variant="h5" >
                        Connexion
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <FormControl fullWidth variant="outlined" id='form-control-login'>
                            <TextField
                                color='primary'
                                margin="normal"
                                fullWidth
                                id="usernameormail"
                                label="Nom d'utilisateur ou adresse mail"
                                name="usernameormail"
                                onChange={(e) => setValues({ ...values, usernameormail: e.target.value })}
                                className="textField"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined" id='form-control-login'>
                            <TextField
                                color='primary'
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                onChange={(e) => setValues({ ...values, password: e.target.value })}
                                className="textField"
                            />
                        </FormControl>
                        {error && <div className="alert alert-danger">{error}</div>}

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                            disabled={isLoading}
                            className="submitButton"
                        >
                            {isLoading ? (
                                <CircularProgress size={25} color="inherit" />
                            ) : (
                                'Se connecter'
                            )}
                        </Button>

                        <Typography variant="body2" color="textSecondary" align="center">
                            <Link to="/legal-notice" className="link">Mentions Légales</Link>
                        </Typography>
                    </form>

                </Grid>
            </Grid>
        </Container>
    );
};

export default Login;