import React, { useState, useEffect, useContext } from 'react';
import axios from '../../Components/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Button,
    Select,
    MenuItem,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    InputLabel,
    OutlinedInput,
    FormControl,
    Container,
    Modal,
    Box,
    Alert,
    AlertTitle,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    CircularProgress
} from '@mui/material';
import Layout from '../../Components/Layout/Layout';
import UpdateProduct from '../../Components/UpdateProduct';
import VerifImage from '../../Components/VerifImage';
import { AuthContext } from '../../Components/context/AuthContext';
import { ShoppingCartContext } from '../../Components/context/ShoppingCartContext';
import './_product.scss';

const Product = () => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const navigate = useNavigate();
    const { currentUserId, currentUserRole, logout } = useContext(AuthContext);
    const { updateNumberOfProducts, numberOfProducts } = useContext(ShoppingCartContext);
    const productOrCart = useLocation().state?.product;
    const modify = useLocation().state?.modify;
    const [quantities, setQuantities] = useState([]);
    const [panier_id] = useState(productOrCart?.panier_id || null);
    const [selectedQuantity, setSelectedQuantity] = useState(productOrCart?.quantite || 50);
    const [image_recto, setFrontImage] = useState(productOrCart?.image_recto || null);
    const [image_verso, setBackImage] = useState(productOrCart?.image_verso || null);
    const [printType, setPrintType] = useState(productOrCart?.mode_impression || 'Quadri recto');
    const [validationError, setValidationError] = useState(null);
    const [isValidationError, setIsValidationError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentText, setDialogContentText] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [oldFrontPDFPublicId, setOldFrontPDFPublicId] = useState(productOrCart?.image_recto || null);
    const [oldBackPDFPublicId, setOldBackPDFPublicId] = useState(productOrCart?.image_verso || null);

    useEffect(() => {
        if (validationError) {
            setIsValidationError(true);
        } else {
            setIsValidationError(false);
        }
    }, [validationError]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (oldFrontPDFPublicId && modify === undefined) {
                deleteCloudinaryFile(oldFrontPDFPublicId);
            }
            if (oldBackPDFPublicId && modify === undefined) {
                deleteCloudinaryFile(oldBackPDFPublicId);
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [oldFrontPDFPublicId, oldBackPDFPublicId, modify]);

    useEffect(() => {
        if (productOrCart == null) {
            navigate('/');
        }

        axios.get('/getAllQuantities')
            .then(response => {
                const quantitiesData = response.data.quantities.map(item => item.valeur);
                setQuantities(quantitiesData);
            })
            .catch(async error => {
                if (error.response && error.response.status === 403) {
                    const res = await logout();
                    if (res.data.Status === 'deconnected') {
                        navigate('/login');
                    } else {
                        alert(res.data.Error);
                    }
                } else {
                    console.error('Erreur lors de la récupération des quantités : ', error);
                }
            });
    }, [selectedQuantity, productOrCart?.produit_id, navigate, productOrCart, logout]);

    useEffect(() => {
        const checkFileExistence = async (filePublicId) => {
            try {
                const response = await axios.get(`/product/pdf-exists/${filePublicId}`);
                const fileExists = response.data.exists;
                return fileExists;
            } catch (error) {
                console.error('Erreur lors de la vérification du fichier sur le backend', error);
                return false;
            }
        };

        const handleCheckExistence = async (imageType) => {
            if (productOrCart && productOrCart[imageType]) {
                const filePublicId = `${productOrCart[imageType].replace(/\.[^/.]+$/, "")}`;
                const fileExists = await checkFileExistence(filePublicId);

                if (fileExists) {
                    if (imageType === "image_recto") {
                        setFrontImage(productOrCart[imageType]);
                        setOldFrontPDFPublicId(productOrCart[imageType]);
                    }
                    else {
                        setBackImage(productOrCart[imageType]);
                        setOldBackPDFPublicId(productOrCart[imageType]);
                    }
                } else {
                    if (imageType === "image_recto") {
                        setFrontImage(null);
                        setOldFrontPDFPublicId(null);
                    }
                    else {
                        setBackImage(null);
                        setOldBackPDFPublicId(null);
                    }
                }
            }
        };

        if (panier_id == null) {
            setSelectedQuantity(50);
            setFrontImage(null);
            setBackImage(null);
            setPrintType('Quadri recto');
            setValidationError(null);
            setIsValidationError(false);
        } else {
            handleCheckExistence('image_recto');
            handleCheckExistence('image_verso');
        }
    }, [panier_id, productOrCart]);

    window.addEventListener('beforeunload', function (e) {
        deleteImagesFromCloudinary();
    });

    const handleNavigateAndDelete = async () => {
        await deleteImagesFromCloudinary();
        navigate('/');
    };

    async function deleteImagesFromCloudinary() {
        const currentURL = window.location.href;
        if (currentURL.includes("/product")) {
            if (!modify) {
                if (image_recto) {
                    await deleteCloudinaryFile(image_recto.replace(/\.[^/.]+$/, ""));
                }
                if (image_verso) {
                    await deleteCloudinaryFile(image_verso.replace(/\.[^/.]+$/, ""));
                }
            }
            else {
                if (oldFrontPDFPublicId && !oldFrontPDFPublicId.endsWith(".pdf")) {
                    await deleteCloudinaryFile(oldFrontPDFPublicId);
                }
                if (oldBackPDFPublicId && !oldBackPDFPublicId.endsWith(".pdf")) {
                    await deleteCloudinaryFile(oldBackPDFPublicId);
                }
            }
        }
    }

    const handlePrintTypeChange = async (e) => {
        if (image_verso) {
            const fileNameWithoutExtension = image_verso.replace(/\.[^/.]+$/, "");
            await deleteCloudinaryFile(fileNameWithoutExtension);
        }
        setBackImage(null);
        setPrintType(e.target.value);
    };

    const handleAddToCart = async (event) => {
        event.preventDefault();
        if (numberOfProducts === 0 || modify === "shoppingCart") {
            if (printType === 'Quadri recto' && !image_recto) {
                setValidationError("Il est nécessaire de télécharger au moins un fichier. Le nom du fichier ne doit pas contenir d'espace entre le nom et l'extension.");
                return;
            }

            if (printType === 'Quadri recto/verso') {
                if (!image_recto) {
                    setValidationError("Il est nécessaire de télécharger au moins un fichier. Le nom du fichier ne doit pas contenir d'espace entre le nom et l'extension.");
                    return;
                }
                if (!image_verso) {
                    setValidationError('Il est nécessaire de télécharger un second fichier pour l\'impression verso');
                    return;
                }
            }

            setShowDialog(true);
        }
        else {
            setShowModal(true);
            return;
        }
    };

    const handleDialogAddToCart = async () => {
        try {
            setShowDialog(false);
            setIsLoading(true);
            const formData = {
                panier_id,
                id_utilisateur: currentUserId,
                id_produit: productOrCart?.produit_id,
                quantite: selectedQuantity,
                mode_impression: printType,
                image_recto,
                image_verso,
            };

            await axios.post('/shoppingcart', formData);
            setIsLoading(false);
            updateNumberOfProducts();
            navigate("/shoppingcart");
            setShowDialog(false);
        } catch (error) {
            setValidationError("Une erreur est survenue, merci de recharger la page et de réessayer.");
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleFrontImageChange = async (e) => {
        setValidationError(null);
        const file = e.target.files[0];

        if (!file) {
            setFrontImage(null);
            return;
        }

        if (file.type !== 'application/pdf') {
            setValidationError('Le fichier doit être un PDF.');
            setFrontImage(null);
            return;
        }

        const formData = new FormData();
        formData.append('pdf', file);

        try {
            setIsLoading(true);

            if (oldFrontPDFPublicId) {
                await deleteCloudinaryFile(oldFrontPDFPublicId);
            }

            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            const response = await axios.post(`/upload-pdf/${fileNameWithoutExtension}`, formData);

            if (response.data) {
                if (response.data.message && response.data.message.includes('File size too large')) {
                    setValidationError('La taille du fichier est trop volumineuse.');
                } else if (response.data.name && response.data.name.includes('Error')) {
                    setValidationError('Une erreur est survenue lors du téléchargement du fichier.');
                } else {
                    setFrontImage(file.name);
                    setOldFrontPDFPublicId(file.name.split('.').slice(0, -1).join('.'));
                }
            }
        } catch (error) {
            console.error('Erreur lors de l\'upload PDF:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBackImageChange = async (e) => {
        setValidationError(null);
        const file = e.target.files[0];

        if (!file) {
            setBackImage(null);
            return;
        }

        if (file.type !== 'application/pdf') {
            setValidationError('Le fichier doit être un PDF.');
            setBackImage(null);
            return;
        }

        const formData = new FormData();
        formData.append('pdf', file);

        try {
            setIsLoading(true);

            if (oldBackPDFPublicId) {
                await deleteCloudinaryFile(oldBackPDFPublicId);
            }

            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            const response = await axios.post(`/upload-pdf/${fileNameWithoutExtension}`, formData);

            if (response.data) {
                if (response.data.message && response.data.message.includes('File size too large')) {
                    setValidationError('La taille du fichier est trop volumineuse.');
                } else if (response.data.name && response.data.name.includes('Error')) {
                    setValidationError('Une erreur est survenue lors du téléchargement du fichier.');
                } else {
                    setBackImage(file.name);
                    setOldBackPDFPublicId(file.name.split('.').slice(0, -1).join('.'));
                }
            }
        } catch (error) {
            console.error('Erreur lors de l\'upload PDF:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFrontImageDelete = async () => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer le fichier sélectionné ?\n\nCette action supprimera définitivement le fichier associé. Si vous continuez, veuillez noter que vous devrez soit sélectionner à nouveau un fichier pour ce produit, soit supprimer le panier existant et le recommencer.");

        if (isConfirmed) {
            const rectoWithoutExtension = image_recto.replace(/\.[^/.]+$/, "");
            await deleteCloudinaryFile(rectoWithoutExtension);
            setFrontImage(null);
            setOldFrontPDFPublicId(null);
        }
    };

    const handleBackImageDelete = async () => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer le fichier sélectionné ?\n\nCette action supprimera définitivement le fichier associé. Si vous continuez, veuillez noter que vous devrez soit sélectionner à nouveau un fichier pour ce produit, soit supprimer le panier existant et le recommencer.");

        if (isConfirmed) {
            const versoWithoutExtension = image_verso.replace(/\.[^/.]+$/, "");
            await deleteCloudinaryFile(versoWithoutExtension);
            setBackImage(null);
            setOldBackPDFPublicId(null);
        }
    };

    const handleEditOrCreateClick = (product) => {
        setSelectedProduct(product);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        window.location.replace('/');
    };

    const handleDeleteClick = (productId, image) => {
        const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce produit ?\n\nToutes les commandes et tous les paniers associés à ce produit seront supprimés définitivement!");

        if (isConfirmed) {
            deleteProduct(productId, image);
        }
    };

    const deleteProduct = async (productId, image) => {
        try {
            setIsLoading(true);
            deleteCloudinaryImage(image);
            await axios.delete(`/deleteProduct/${productId}`);
            setIsLoading(false);
            setDialogTitle("Produit supprimé");
            setDialogContentText("Le produit a été supprimé avec succès.");
            setIsDialogOpen(true);

        } catch (error) {
            console.error('Error deleting product:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteCloudinaryImage = async (publicId) => {
        try {
            await axios.delete(`/product/image/${publicId}`);
        } catch (error) {
            console.error('Error deleting image from backend:', error.message);
        }
    };

    const deleteCloudinaryFile = async (publicId) => {
        try {
            await axios.delete(`/product/file/${publicId}`);
        } catch (error) {
            console.error('Error deleting file from backend:', error.message);
        }
    };

    const handleSaveProduct = async (formData, isEditMode) => {
        try {

            const formDataWithoutImage = { ...formData };

            if (isEditMode) {
                await axios.put('/updateProduct', formDataWithoutImage);
                setDialogTitle("Produit modifié");
                setDialogContentText("Les informations du produit ont été modifiées avec succès.");
            } else {
                await axios.post('/product', formDataWithoutImage);
                setDialogTitle("Nouveau produit enregistré");
                setDialogContentText("Un nouveau produit a été enregistré avec succès.");
            }

            setIsDialogOpen(true);
        } catch (error) {
            console.error('Error updating/registering product:', error.message);
        }
    };

    const handleCloseDialog = async (newImagePublicId) => {
        if (newImagePublicId) {
            deleteCloudinaryImage(newImagePublicId);
        }
        setOpenDialog(false);
        setSelectedProduct(null);
    };

    return (
        <Layout>
            <Container>
                <div id="button-product-back" className="button-product-container">
                    <Button
                        variant='contained'
                        onClick={handleNavigateAndDelete}
                        color='info'
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <CircularProgress size={25} color="inherit" />
                        ) : (
                            <>
                                <ArrowBackIcon /> Retour au catalogue
                            </>
                        )}
                    </Button>
                </div>
                <div className='canvas'>
                    <div className='product-card-product'>
                        <div className='images'>
                            <img
                                src={`https://res.cloudinary.com/${cloudName}/image/upload/gobilab/illustrations/${productOrCart.image}`}
                                alt={productOrCart.nom}
                            />
                        </div>
                        <form className="form-container">
                            <div className='form-wrapper'>
                                <div className='text-center-product'>
                                    <Typography variant="h4">{productOrCart?.nom}</Typography>
                                </div>
                                <div className="form-field">
                                    <FormControl id='form-control-product' variant="outlined">
                                        <InputLabel htmlFor="outlined-quantity">Quantité d’exemplaires</InputLabel>
                                        <Select
                                            value={selectedQuantity}
                                            onChange={(e) => setSelectedQuantity(e.target.value)}
                                            input={<OutlinedInput label="Quantité d’exemplaires" name="quantity" id="outlined-quantity" />}
                                            color='primary'
                                        >
                                            <MenuItem value="" disabled>Choisissez une quantité</MenuItem>
                                            {quantities.map(quantity => (
                                                <MenuItem key={quantity} value={quantity}>
                                                    {quantity}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="form-field">
                                    <FormControl id='form-control-product' variant="outlined">
                                        <TextField
                                            color='primary'
                                            label="Hauteur"
                                            name='length'
                                            value={productOrCart?.hauteur}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-field">
                                    <FormControl id='form-control-product' variant="outlined">

                                        <TextField
                                            color='primary'
                                            label="Largeur"
                                            name='width'
                                            value={productOrCart?.largeur}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-field">
                                    <FormControl id='form-control-product' variant="outlined">
                                        <InputLabel htmlFor="outlined-printType">Impression</InputLabel>
                                        <Select
                                            name='printType'
                                            value={printType}
                                            onChange={handlePrintTypeChange}
                                            color='primary'
                                            label="Impression"
                                        >
                                            <MenuItem value='Quadri recto'>Quadri recto</MenuItem>
                                            <MenuItem value='Quadri recto/verso'>Quadri recto/verso</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="form-field">
                                    <label > Téléchargement recto :</label>
                                    {image_recto ? (
                                        <div>
                                            <TextField
                                                color='primary'
                                                type='text'
                                                readOnly
                                                fullWidth
                                                value={image_recto}
                                                className="read-only-input"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleFrontImageDelete}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <label htmlFor="image-input-front">
                                                <Button variant="outlined" component="span" color="primary" fullWidth sx={{ marginTop: '10px' }} disabled={isLoading}>
                                                    Sélectionner un fichier
                                                </Button>
                                            </label>
                                            <input type="file" id="image-input-front" onChange={(e) => handleFrontImageChange(e)} style={{ display: 'none' }} accept="application/pdf" />
                                        </>
                                    )}
                                </div>
                                {printType === 'Quadri recto/verso' && (
                                    <div className="form-field">
                                        <label >Téléchargement verso :</label>
                                        {image_verso ? (
                                            <div>
                                                <TextField
                                                    color='primary'
                                                    type='text'
                                                    readOnly
                                                    fullWidth
                                                    value={image_verso}
                                                    className="read-only-input"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleBackImageDelete}>
                                                                    <ClearIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <label htmlFor="image-input-back">
                                                    <Button variant="outlined" component="span" color="primary" fullWidth sx={{ marginTop: '10px' }} disabled={isLoading}>
                                                        Sélectionner un fichier
                                                    </Button>
                                                </label>
                                                <input type="file" id="image-input-back" onChange={(e) => handleBackImageChange(e)} style={{ display: 'none' }} accept="application/pdf" />
                                            </>
                                        )}

                                    </div>
                                )}
                                <div id="button-product">
                                    <Button variant='contained' color='info' onClick={handleAddToCart} style={{ marginBottom: '10px' }} disabled={isLoading}>
                                        {isLoading ? (
                                            <CircularProgress size={25} color="inherit" />
                                        ) : (
                                            'Ajouter au panier'
                                        )}
                                    </Button>
                                    {currentUserRole === 'administrateur' && (
                                        <>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleEditOrCreateClick(productOrCart)}
                                                style={{ marginBottom: '10px' }}
                                                disabled={isLoading}>
                                                {isLoading ? (
                                                    <CircularProgress size={25} color="inherit" />
                                                ) : (
                                                    'Éditer'
                                                )}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => handleDeleteClick(productOrCart?.produit_id, productOrCart?.image)}
                                                disabled={isLoading}>
                                                {isLoading ? (
                                                    <CircularProgress size={25} color="inherit" />
                                                ) : (
                                                    'Supprimer'
                                                )}
                                            </Button>
                                        </>
                                    )}
                                </div>
                                <div className={`alert mt-2 ${isValidationError ? 'alert-danger' : 'alert-warning'}`}>
                                    {isValidationError ? validationError : "Il est nécessaire de télécharger au moins un fichier. Le nom du fichier ne doit pas contenir d'espace entre le nom et l'extension."}
                                </div>
                            </div>
                        </form>
                    </div>
                    <Modal
                        open={showModal}
                        onClose={() => setShowModal(false)}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                boxShadow: 24,
                            }}
                        >
                            <Alert severity="error">
                                <AlertTitle style={{ fontSize: '1.5rem' }}>Erreur</AlertTitle>
                                <Typography variant="body1" style={{ fontSize: '1rem' }}>
                                    Vous ne pouvez pas ajouter plus d'un produit dans le panier
                                </Typography>
                            </Alert>
                        </Box>
                    </Modal>
                    <Dialog
                        open={showDialog}
                        onClose={() => setShowDialog(false)}
                        aria-labelledby="verif-image-dialog"
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogContent>
                            <VerifImage
                                rectoImage={image_recto}
                                versoImage={image_verso}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowDialog(false)} color="primary">
                                Fermer
                            </Button>
                            <Button onClick={handleDialogAddToCart} color="primary">
                                Ajouter au panier
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{dialogContentText}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openDialog}>
                        <DialogContent>
                            <UpdateProduct product={selectedProduct} onSave={handleSaveProduct} onClose={handleCloseDialog} />
                        </DialogContent>
                    </Dialog>
                </div>
            </Container>
        </Layout>
    );
};

export default Product;