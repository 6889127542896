import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Home/Home";
import ProtectedRoute from "./Components/ProtectedRoute";
import Login from "./Pages/Login/Login";
import LegalNotice from "./Pages/LegalNotice/LegalNotice";
import NotFoundPage from "./Pages/Notfound/Notfound";
import Product from "./Pages/Product/Product";
import ShoppingCart from "./Pages/Shoppingcart/Shoppingcart";
import Savedshoppingcart from "./Pages/Savedshoppingcart/Savedshoppingcart";
import Shipping from "./Pages/Shipping/Shipping";
import Checkout from "./Pages/Checkout/Checkout";
import Orders from "./Pages/Order/Order";
import OrderDetails from "./Pages/OrderDetails/OrderDetails";
import User from "./Pages/User/User";
import { AuthContextProvider } from "./Components/context/AuthContext";
import { ShoppingCartProvider } from "./Components/context/ShoppingCartContext";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <ShoppingCartProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/legal-notice" element={<LegalNotice />} />

              <Route
                path="/"
                element={<ProtectedRoute><Homepage /></ProtectedRoute>}
              />
              <Route path="/product" element={<ProtectedRoute><Product /></ProtectedRoute>} />
              <Route path="/shoppingcart" element={<ProtectedRoute><ShoppingCart /></ProtectedRoute>} />
              <Route path="/savedshoppingcart" element={<ProtectedRoute><Savedshoppingcart /></ProtectedRoute>} />
              <Route path="/shipping" element={<ProtectedRoute><Shipping /></ProtectedRoute>} />
              <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
              <Route path="/order" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
              <Route path="/order-details" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
              <Route path="/user" element={<ProtectedRoute><User /></ProtectedRoute>} />

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Router>
        </ShoppingCartProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;