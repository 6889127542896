import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Components/context/AuthContext';
import axios from '../../Components/axios';
import Layout from '../../Components/Layout/Layout';
import UpdateUser from '../../Components/UpdateUser';
import { useNavigate } from 'react-router-dom';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AddIcon from '@mui/icons-material/Add';
import {
    Button,
    TextField,
    FormControl,
    Container,
    Grid,
    Typography,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    InputAdornment,
    Tab,
    CircularProgress,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TableContainer
} from '@mui/material';
import "./_user.scss"

const User = () => {
    const [value, setValue] = useState('profil');
    const { currentUserId, currentUserRole, logout } = useContext(AuthContext);
    const [errorNom, setErrorNom] = useState('');
    const [errorPrenom, setErrorPrenom] = useState('');
    const [errorNomUtilisateur, setErrorNomUtilisateur] = useState('');
    const [errorMail, setErrorMail] = useState('');
    const [errorTelephone, setErrorTelephone] = useState('');
    const [errorTelephoneFixe, setErrorTelephoneFixe] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContentText, setDialogContentText] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordStartedTyping, setPasswordStartedTyping] = useState(false);
    const [profileTyping, setProfileTyping] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [isChangePasswordButtonDisabled, setIsChangePasswordButtonDisabled] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [emails, setEmails] = useState([]);
    const [newEmail, setNewEmail] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const emailRegex = /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const [quantities, setQuantities] = useState([]);
    const navigate = useNavigate();

    const isEmailValid = (email) => {
        return emailRegex.test(email);
    };

    const [passwordConditions, setPasswordConditions] = useState({
        length: false,
        number: false,
        uppercase: false,
    });
    const [passwordError, setPasswordError] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const [user, setUser] = useState({
        utilisateur_id: 0,
        nom: '',
        prenom: '',
        nom_utilisateur: '',
        mail: '',
        telephone: '',
        telephone_fixe: '',
        derniere_connexion: '',
        mot_de_passe: '',
        user_role: '',
    });

    useEffect(() => {
        const userId = currentUserId;
        axios.get(`/getUser/${userId}`)
            .then(response => {
                setUser(response.data.user);
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });

        axios.get('/getAllAddresses')
            .then(response => {
                setAddresses(response.data);
            })
            .catch(error => {
                console.error('Error fetching addresses:', error);
            });
        const areAllConditionsMet = passwordConditions.length && passwordConditions.number && passwordConditions.uppercase;

        setIsChangePasswordButtonDisabled(!areAllConditionsMet);

        if (currentUserRole === 'administrateur') {
            axios.get('/getAllUsers/withoutSensitiveData')
                .then(response => {
                    setUsers(response.data.users);
                })
                .catch(error => {
                    console.error('Error fetching users:', error);
                });

            axios.get('/getAllAddressesEmail')
                .then(response => {
                    setEmails(response.data.addresses);
                })
                .catch(error => {
                    console.error('Error fetching emails:', error);
                });

            axios.get('/getAllQuantities')
                .then(response => {
                    setQuantities(response.data.quantities);
                })
                .catch(error => {
                    console.error('Error fetching quantities:', error);
                });
        }

    }, [currentUserId, passwordConditions, currentUserRole]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeValueUser = (event) => {
        setProfileTyping(false);
        const { name, value } = event.target;

        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));

        switch (name) {
            case 'nom':
                setErrorNom('');
                break;
            case 'prenom':
                setErrorPrenom('');
                break;
            case 'nom_utilisateur':
                setErrorNomUtilisateur('');
                break;
            case 'mail':
                setErrorMail('');
                break;
            case 'telephone':
                setErrorTelephone('');
                break;
            case 'telephone_fixe':
                setErrorTelephoneFixe('');
                break;
            default:
                break;
        }
    };

    const handleSaveProfile = async () => {
        if (!user.nom || !user.prenom || !user.nom_utilisateur || !user.mail) {
            setErrorNom(!user.nom ? 'Le champ est requis.' : '');
            setErrorPrenom(!user.prenom ? 'Le champ est requis.' : '');
            setErrorNomUtilisateur(!user.nom_utilisateur ? 'Le champ est requis.' : '');
            setErrorMail(!user.mail ? 'Le champ est requis.' : '');
            return;
        }

        if (user.telephone && !/^\d{10}$/.test(user.telephone)) {
            setErrorTelephone('Veuillez saisir un numéro de téléphone valide (10 chiffres).');
            return;
        }

        if (user.telephone_fixe && !/^\d{10}$/.test(user.telephone_fixe)) {
            setErrorTelephoneFixe('Veuillez saisir un numéro de téléphone fixe valide (10 chiffres).');
            return;
        }

        if (!emailRegex.test(user.mail)) {
            setErrorMail('Veuillez saisir une adresse e-mail valide.');
            return;
        }

        if (window.confirm("Êtes-vous sûr de vouloir enregistrer les modifications du profil ?")) {
            setIsLoading(true);

            const updatedUserData = {
                utilisateur_id: currentUserId,
                nom: user.nom,
                prenom: user.prenom,
                nom_utilisateur: user.nom_utilisateur,
                mail: user.mail,
                telephone: user.telephone,
                telephone_fixe: user.telephone_fixe,
            };

            await axios.put('/updateUser', updatedUserData)
                .then(async () => {
                    setIsLoading(false);
                    setDialogTitle("Informations modifiées");
                    setDialogContentText("Vos informations ont été modifiées avec succès.");
                    setIsDialogOpen(true);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.error('Error saving profile:', error);
                });
        }
    };

    const handleChangePassword = async () => {
        setPasswordError({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });

        if (!currentPassword || !newPassword || !confirmNewPassword) {
            setPasswordError({
                currentPassword: !currentPassword ? 'Veuillez saisir le mot de passe actuel.' : '',
                newPassword: !newPassword ? 'Veuillez saisir le nouveau mot de passe.' : '',
                confirmNewPassword: !confirmNewPassword ? 'Veuillez confirmer le nouveau mot de passe.' : '',
            });
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setPasswordError({
                confirmNewPassword: 'Les nouveaux mots de passe ne correspondent pas.',
            });
            return;
        }

        if (window.confirm("Êtes-vous sûr de vouloir changer le mot de passe ?")) {
            setIsLoading(true);
            const changePasswordData = {
                userId: currentUserId,
                oldPassword: currentPassword,
                newPassword: newPassword,
            };

            await axios.post('/changePassword', changePasswordData)
                .then(() => {
                    setIsLoading(false);
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmNewPassword('');

                    setDialogTitle("Mot de passe modifié");
                    setDialogContentText("Votre mot de passe a été modifié avec succès");
                    setIsDialogOpen(true);
                    setIsChangePasswordButtonDisabled(true);
                    setPasswordStartedTyping(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    setPasswordError({
                        currentPassword: error.response.data.Error,
                    });
                });
        }
    };

    const handleChangeCurrentPassword = (event) => {
        setCurrentPassword(event.target.value);
        setPasswordError((prevErrors) => ({
            ...prevErrors,
            currentPassword: '',
        }));
    };

    const handleAddEmail = () => {
        if (!isEmailValid(newEmail)) {
            setErrorMail('Veuillez entrer une adresse email valide.');
            return;
        }

        const confirmed = window.confirm('Êtes-vous sûr de vouloir ajouter cette adresse email ?\n\nAprès validation, la page sera rechargée !');

        if (confirmed) {
            axios.post('/addressEmail', { email: newEmail })
                .then(response => {
                    setNewEmail('');
                    setErrorMail('');
                    window.location.reload();
                })
                .catch(error => {
                    console.error('Error adding email:', error);
                });
        }
    };

    const handleDeleteEmail = (emailId) => {
        const confirmed = window.confirm('Êtes-vous sûr de vouloir supprimer cette adresse email ?');

        if (confirmed) {
            axios.delete(`/deleteAddressEmail/${emailId}`)
                .then(() => {
                    const filteredEmails = emails.filter(email => email.id !== emailId);
                    setEmails(filteredEmails);
                })
                .catch(error => {
                    console.error('Error deleting email:', error);
                });
        }
    };

    const handleDialogClose = async () => {
        setIsDialogOpen(false);
        if (dialogTitle === "Informations modifiées") {
            const res = await logout();
            if (res.data.Status === 'deconnected') {
                navigate('/login');
            } else {
                alert(res.data.Error);
            }
        }
        else {
            window.location.reload();
        }
    };

    const handleChangeNewPassword = (event) => {
        setPasswordError((prevErrors) => ({
            ...prevErrors,
            newPassword: '',
        }));

        const newPasswordValue = event.target.value;
        setPasswordStartedTyping(true);
        setPasswordConditions({
            length: newPasswordValue.length >= 7,
            number: /\d/.test(newPasswordValue),
            uppercase: /[A-Z]/.test(newPasswordValue),
        });
        setNewPassword(newPasswordValue);
    };

    const handleChangeConfirmNewPassword = (event) => {
        setConfirmNewPassword(event.target.value);
        setPasswordError((prevErrors) => ({
            ...prevErrors,
            confirmNewPassword: '',
        }));
    };

    const handleEditOrCreateClick = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedUser(null);
    };

    const handleSaveUser = async (formData, isEditMode) => {
        try {

            if (isEditMode) {
                await axios.put('/updateUserFromAdmin', formData);
                setDialogTitle("Utilisateur modifié");
                setDialogContentText("Les informations de l'utilisateur ont été modifiées avec succès.");
            } else {
                await axios.post('/register', formData);
                setDialogTitle("Nouvel utilisateur enregistré");
                setDialogContentText("Un nouvel utilisateur a été enregistré avec succès.");
            }

            setIsDialogOpen(true);
        } catch (error) {
            console.error('Error updating/registering user:', error.message);
        }
    };

    const handleDelete = async (userId) => {
        if (userId === currentUserId) {
            setDialogTitle("Erreur de suppression");
            setDialogContentText("Vous ne pouvez pas supprimer l'utilisateur actuellement connecté.");
            setIsDialogOpen(true);
            return;
        }

        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur?\n\nToutes les commandes et tous les paniers associés à cet utilisateur seront supprimés définitivement !")) {
            try {
                setIsLoading(true);
                const response = await axios.delete(`/deleteUser/${userId}`);
                if (response.status === 200) {
                    setIsLoading(false);
                    setDialogTitle("Utilisateur supprimé");
                    setDialogContentText("L'utilisateur a été supprimé avec succès");
                    setIsDialogOpen(true);
                } else {
                    setIsLoading(false);
                    console.error(`Error deleting user: ${response.statusText}`);
                }
            } catch (error) {
                console.error("An error occurred while deleting the user:", error);
            }
            finally {
                setIsLoading(false);
            }
        }
    };

    function formatLastConnection(lastConnectionString) {
        const lastConnectionDate = new Date(lastConnectionString);
        return `Dernière connexion: ${lastConnectionDate.toLocaleString()}`;
    }

    const handleEditChange = (field, value, index) => {
        setQuantities((prevQuantities) => {
            const updatedQuantities = [...prevQuantities];
            updatedQuantities[index] = { ...updatedQuantities[index], [field]: value };
            return updatedQuantities;
        });
    };

    const handleUpdateQuantity = (quantiteId, index) => {
        const editedQuantity = quantities[index];

        axios.post('/updateQuantity', {
            quantite_id: quantiteId,
            valeur: editedQuantity.valeur,
            prix: editedQuantity.prix
        })
            .then(response => {
                alert('Quantité mise à jour avec succès.');
            })
            .catch(error => {
                console.error('Error updating quantity:', error);
            });
    };

    const handleAddQuantity = async () => {
        const newValue = document.getElementById('new-value').value;
        const newPrice = document.getElementById('new-price').value;

        if (newValue && newPrice) {
            try {
                await axios.post('/quantity', {
                    valeur: newValue,
                    prix: newPrice,
                });

                setQuantities((prevQuantities) => [...prevQuantities, { valeur: newValue, prix: newPrice }]);

                document.getElementById('new-value').value = '';
                document.getElementById('new-price').value = '';

                alert('Quantité ajoutée avec succès.');
            } catch (error) {
                console.error('Erreur lors de l\'ajout de la quantité :', error);
            }
        }
    };

    const handleDeleteQuantity = async (quantiteId) => {
        try {
            await axios.delete(`/deleteQuantity/${quantiteId}`);

            setQuantities((prevQuantities) => prevQuantities.filter(q => q.quantite_id !== quantiteId));

            alert('Quantité supprimée avec succès.');
        } catch (error) {
            console.error(`Erreur lors de la suppression de la quantité ${quantiteId}:`, error);
        }
    };

    return (
        <Layout>
            <Container maxWidth='lg' >
                <div id='container-user'>
                    <h1 className='title-user'>Paramètres</h1>
                    <TabContext value={value}>
                        <Container sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} variant='scrollable' scrollButtons="auto" allowScrollButtonsMobile>
                                <Tab label="Profil" value="profil" />
                                <Tab label="Mot de passe" value="password" />
                                <Tab label="Adresse" value="address" />
                                {currentUserRole === 'administrateur' && <Tab label="Utilisateurs" value="users" />}
                                {currentUserRole === 'administrateur' && <Tab label="Mail et quantités" value="emails-quantity" />}
                            </TabList>
                        </Container>
                        <TabPanel value="profil" style={{ textAlign: 'center' }} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Nom"
                                                name='nom'
                                                value={user.nom || ''}
                                                fullWidth
                                                onChange={handleChangeValueUser}
                                                error={!!errorNom}
                                                helperText={errorNom}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Prénom"
                                                name='prenom'
                                                value={user.prenom || ''}
                                                fullWidth
                                                onChange={handleChangeValueUser}
                                                error={!!errorPrenom}
                                                helperText={errorPrenom}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Nom d'utilisateur"
                                                name='nom_utilisateur'
                                                value={user.nom_utilisateur || ''}
                                                fullWidth
                                                onChange={handleChangeValueUser}
                                                error={!!errorNomUtilisateur}
                                                helperText={errorNomUtilisateur}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="E-mail"
                                                name='mail'
                                                value={user.mail || ''}
                                                fullWidth
                                                onChange={handleChangeValueUser}
                                                error={!!errorMail}
                                                helperText={errorMail}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Téléphone"
                                                name='telephone'
                                                value={user.telephone || ''}
                                                fullWidth
                                                onChange={handleChangeValueUser}
                                                error={!!errorTelephone}
                                                helperText={errorTelephone}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Téléphone Fixe"
                                                name='telephone_fixe'
                                                value={user.telephone_fixe || ''}
                                                fullWidth
                                                onChange={handleChangeValueUser}
                                                error={!!errorTelephoneFixe}
                                                helperText={errorTelephoneFixe}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={12} className='button-container-user'>
                                    <Button onClick={handleSaveProfile} disabled={profileTyping}>
                                        {isLoading ? (
                                            <CircularProgress size={25} color="inherit" />
                                        ) : (
                                            'Enregistrer'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} className="alert mt-3 alert-warning">
                                Si vous modifiez vos informations, vous serez déconnecté afin de les mettre à jour.
                            </Grid>
                        </TabPanel>
                        <TabPanel value="password">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Mot de passe actuel"
                                                type="password"
                                                value={currentPassword || ''}
                                                onChange={handleChangeCurrentPassword}
                                                fullWidth
                                                error={!!passwordError.currentPassword}
                                                helperText={passwordError.currentPassword}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Nouveau mot de passe"
                                                type={showPassword ? 'text' : 'password'}
                                                value={newPassword || ''}
                                                onChange={handleChangeNewPassword}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setShowPassword(!showPassword)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={!!passwordError.newPassword}
                                                helperText={passwordError.newPassword}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="form-field-user">
                                        <FormControl id='form-control-user' variant="outlined">
                                            <TextField
                                                color='primary'
                                                label="Confirmer le nouveau mot de passe"
                                                type="password"
                                                value={confirmNewPassword || ''}
                                                onChange={handleChangeConfirmNewPassword}
                                                fullWidth
                                                error={!!passwordError.confirmNewPassword}
                                                helperText={passwordError.confirmNewPassword}
                                            />
                                        </FormControl>
                                        <div className="password-conditions">
                                            <ul>
                                                <li style={{ color: passwordStartedTyping ? (passwordConditions.length ? 'green' : 'red') : 'black' }}>
                                                    Au moins 7 caractères
                                                </li>
                                                <li style={{ color: passwordStartedTyping ? (passwordConditions.number ? 'green' : 'red') : 'black' }}>
                                                    Au moins 1 numéro
                                                </li>
                                                <li style={{ color: passwordStartedTyping ? (passwordConditions.uppercase ? 'green' : 'red') : 'black' }}>
                                                    Au moins 1 lettre majuscule
                                                </li>
                                                <li>
                                                    (suggéré) 1 symbole
                                                </li>
                                                <li>
                                                    (suggéré) 12 caractères
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className='button-container-user'>
                                    <Button onClick={handleChangePassword} disabled={isChangePasswordButtonDisabled}>
                                        {isLoading ? (
                                            <CircularProgress size={25} color="inherit" />
                                        ) : ('Changer le mot de passe'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="address">
                            <Grid container spacing={2}>
                                {addresses.map((address) => (
                                    <Grid key={address.adresse_id} item xs={12} sm={6} md={4} lg={3}>
                                        <Card className="address-card">
                                            <CardContent>
                                                <Typography variant="body1">
                                                    <strong>Code de localisation:</strong> {address.code_localisation}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Entreprise:</strong> {address.entreprise}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Adresse 1:</strong> {address.adresse}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Ville:</strong> {address.ville}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Département / Région:</strong> {address.departement_region}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Code postal:</strong> {address.code_postal}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </TabPanel>
                        <TabPanel value="users">
                            <Grid container spacing={2} direction="row">
                                {users.map((user) => (
                                    <Grid key={user.utilisateur_id} item xs={12} sm={6} md={4} lg={3}>
                                        <Card className="user-card">
                                            <CardContent>
                                                <Typography className='multiline-email' variant="h6">
                                                    {`${user.nom} ${user.prenom}`} {user.utilisateur_id === currentUserId ? '(Vous)' : null}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <a className='multiline-email' href={`mailto:${user.mail}`}>{user.mail}</a>
                                                </Typography>
                                                <Typography variant="body1">
                                                    {formatLastConnection(user.derniere_connexion)}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handleEditOrCreateClick(user)}
                                                    style={{ marginRight: '10px', marginTop: '10px' }}
                                                >
                                                    Editer
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => handleDelete(user.utilisateur_id)}
                                                    style={{ marginTop: '10px' }}
                                                    disabled={user.utilisateur_id === currentUserId}
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress size={25} color="inherit" />
                                                    ) : (
                                                        'Supprimer'
                                                    )}
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Card className="user-card" style={{ textAlign: 'center' }} >
                                        <CardContent>
                                            <Typography variant="h6">
                                                Ajouter un utilisateur
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ marginTop: '10px' }}
                                                onClick={() => handleEditOrCreateClick(null)}
                                            >
                                                Ajouter
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="emails-quantity">
                            {/* Titre de la liste d'adresses email */}
                            <Typography variant="h6" gutterBottom>
                                Adresse mail commandes
                            </Typography>

                            <ul>
                                {emails.map((email) => (
                                    <li key={email && email.id}>
                                        {email && email.email}
                                        <Button onClick={() => handleDeleteEmail(email && email.id)}>Supprimer</Button>
                                    </li>
                                ))}
                            </ul>

                            <TextField
                                label="Nouvelle adresse email"
                                value={newEmail}
                                onChange={(e) => {
                                    setNewEmail(e.target.value);
                                    setErrorMail('');
                                }}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => handleAddEmail()} color="primary">
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!errorMail}
                                helperText={errorMail}
                            />
                            <TableContainer style={{ overflowX: 'auto', maxWidth: '100%' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <strong>Valeur</strong>
                                            </TableCell>
                                            <TableCell>
                                                <strong>Prix</strong>
                                            </TableCell>
                                            <TableCell>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {quantities.map((quantity, index) => (
                                            <TableRow key={quantity.quantite_id}>
                                                <TableCell>
                                                    <TextField
                                                        value={quantity.valeur || ''}
                                                        onChange={(e) => handleEditChange('valeur', e.target.value, index)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={quantity.prix || ''}
                                                        onChange={(e) => handleEditChange('prix', e.target.value, index)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Button size='small' color='success' onClick={() => handleUpdateQuantity(quantity.quantite_id, index)}>Mettre à jour</Button>
                                                    <Button size='small' color='error' onClick={() => handleDeleteQuantity(quantity.quantite_id)}>Supprimer</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell>
                                                <TextField id="new-value" />
                                            </TableCell>
                                            <TableCell>
                                                <TextField id="new-price" />
                                            </TableCell>
                                            <TableCell>
                                                <Button size='small' onClick={handleAddQuantity}>Ajouter</Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </TabContext>
                    <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                        <DialogTitle>{dialogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{dialogContentText}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                                Fermer
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle>Modifier l'utilisateur</DialogTitle>
                        <DialogContent>
                            <UpdateUser user={selectedUser} onSave={handleSaveUser} onClose={handleCloseDialog} />
                        </DialogContent>
                    </Dialog>
                </div>
            </Container>
        </Layout>
    );
};

export default User;