// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0091EA',
    },
    secondary: {
      main: '#E0E0E0',
    },
    error: {
      main: '#D50000',
    },
    warning: {
      main: '#FFAB00',
    },
    info: {
      main: '#78cfef',
    },
    success: {
      main: '#00C853',
    },
    blackbutton: {
      main: '#000000',
    },
    navbar: {
      main: '#B0BEC5',
    }
  },
  typography: {
    fontFamily: '"Nunito Sans", sans-serif', // Remplacez 'VotreFontFamily' par votre famille de police préférée
    // Autres configurations de typographie
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#199cec', // Couleur de survol pour les boutons primaires
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#e3e3e3', // Couleur de survol pour les boutons secondaires
          },
        },
        containedError: {
          '&:hover': {
            backgroundColor: '#d91919', // Couleur de survol pour les boutons d'erreur
          },
        },
        containedWarning: {
          '&:hover': {
            backgroundColor: '#ffb319', // Couleur de survol pour les boutons de mise en garde
          },
        },
        containedInfo: {
          '&:hover': {
            backgroundColor: '#93d8f2', // Couleur de survol pour les boutons d'information
          },
        },
        containedSuccess: {
          '&:hover': {
            backgroundColor: '#19cd64', // Couleur de survol pour les boutons de succès
          },
        },
      },
    },
  },
});

export default theme;